import { Injectable } from '@angular/core';
import { ApiBaseService } from './api-base.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';
import { CookieService } from './cookie.service';
import { CurrentProductService } from './current-product.service';
import { LocalStorageService } from './local-storage.service';
import { LocaleService } from './locale.service';
import { PageTitleService } from './page-title.service';
import { TrackingService } from './tracking.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { ToasterService } from './toaster.service';
import { TimezoneService } from './timezone.service';

@Injectable({
  providedIn: 'root'
})
export class SharedServicesInit {

    private allLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private apiService: ApiBaseService,
        private authGuard: AuthGuardService,
        private authService: AuthService, 
        private cookieService: CookieService,
        private productService: CurrentProductService,
        private localStorageService: LocalStorageService,
        private localeService: LocaleService,
        private pageTitleService: PageTitleService,
        private toasterService: ToasterService,
        private trackingService: TrackingService,
        private timezoneService: TimezoneService) {
        this.initServices();
    }

    private initServices() {
        combineLatest([
            this.apiService.isFinished$(),
            this.authGuard.isFinished$(),
            this.authService.isFinished$(),
            this.cookieService.isFinished$(),
            this.productService.isFinished$(),
            this.localStorageService.isFinished$(),
            this.localeService.isFinished$(),
            this.pageTitleService.isFinished$(),
            this.toasterService.isFinished$(),
            this.trackingService.isFinished$(),
            this.timezoneService.isFinished$()]).subscribe(
            ([apiFinished, guardFinished, authFinished, cookieFinished, productFinished, storageFinished, localeFinished, titleFinished, toasterFinished, trackingFinished, timezoneFinished]) => {

                // if already loaded before and some service emits again, ignore it
                if (this.allLoaded$.getValue()) {
                    return; 
                }

                // make sure all are loaded
                if (apiFinished && guardFinished && authFinished && cookieFinished && productFinished && storageFinished && localeFinished && titleFinished && toasterFinished && trackingFinished && timezoneFinished) {
                    this.allLoaded$.next(true);
                }            
            }
        );
    }

    public allServicesLoaded$(): Observable<boolean> {
        return this.allLoaded$.asObservable();
    }
}
