import {Pipe, PipeTransform} from "@angular/core";
import {LocaleService} from "./locale.service";

@Pipe({
    name: 'translate',
    pure: false //TODO Cata: for the future, find a better solution to update only call on change, not on every change detection cycle
})
export class LocaleTranslatePipe implements PipeTransform {
    constructor(
        private localeService: LocaleService
    ) {
    }
    transform(key: string): string {
        return this.localeService.translate(key);
    }
}