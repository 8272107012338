import {Inject, Injectable} from '@angular/core';
import {catchError, Observable, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {LocaleService} from "./locale.service";
import {CookieService} from "./cookie.service";
import { BaseLoadingService } from './base-loading';

@Injectable({
  providedIn: 'root'
})
export class ApiBaseService extends BaseLoadingService {

  constructor(
      private http: HttpClient,
      private localeService: LocaleService,
      private cookieService: CookieService,
      @Inject('env') private env: any) {
        super();
        this.setFinished();
  }

    public get(path: string, options: any = {}): Observable<any> {
      options.headers = this.getHeaders();
      let url = this.getFullUrl(path);
      return this.http.get(url, options).pipe(catchError(this.handleError));
    }

    public post(path: string, body: any, options: any = {}, requiresAuth: boolean = true): Observable<any> {
        if (!!options.headers) {
            options.headers = this.getHeaders(requiresAuth, options.headers);
        } else {
            options.headers = this.getHeaders(requiresAuth);
        }
        let url = this.getFullUrl(path);
        return this.http.post(url, body, options).pipe(catchError(this.handleError))
    }

    public put(path: string, body: any, options: any = {}, requiresAuth: boolean = true): Observable<any> {
        options.headers = this.getHeaders(requiresAuth);
        let url = this.getFullUrl(path);
        return this.http.put(url, body, options).pipe(catchError(this.handleError))
    }

    public patch(path: string, body: any, options: any = {}, requiresAuth: boolean = true): Observable<any> {
        options.headers = this.getHeaders(requiresAuth);
        let url = this.getFullUrl(path);
        return this.http.patch(url, body, options).pipe(catchError(this.handleError))
    }

    public delete(path: string, options: any = {}, requiresAuth: boolean = true): Observable<any> {
        options.headers = this.getHeaders(requiresAuth);
        let url = this.getFullUrl(path);
        return this.http.delete(url, options).pipe(catchError(this.handleError))
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error("Error Event");
        } else {
            //TODO Cata: add here more logic for each one, maybe some specific Models per error
            switch (error.status) {
                // Client errors
                case 400: // Bad request
                    break;
                case 401: // Unauthorized
                    break;
                case 403: // Forbidden
                    break;
                case 404: // Not found
                    break;
                case 405: // Method not allowed
                    break;
                case 408: // Request timeout
                    break;
                case 410: // Gone
                    break;
                case 412: // Preconditiona failed
                    break;
                case 429: // Too many requests
                    break;
                // Server errors
                case 500: // Internal Server Error
                    break;
                case 501: // Not implemented
                    break;
                case 502: // Bad Gateway
                    break;
                case 503: // Service not available
                    break;
                case 504: // Gateway timeout
                    break;
                case 505: // HTTP version not supported
                    break;
            }
        }
        return throwError(error);
    }

    private getHeaders(requiresAuth: boolean = true, customHeaders?: HttpHeaders): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        headers = headers.set('Accept-Language', this.localeService.getLanguageISO2());
        const token = this.cookieService.getCookie('token');
        if (!!token && requiresAuth) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }

        if (!!customHeaders) {
            // add them one by one to avoid security issues
            let hasContentType = customHeaders.get('Content-Type');
            if (!!hasContentType) {
                headers = headers.set('Content-Type', hasContentType);
            }
        }

        return headers;
    }

    private getFullUrl(url: string): string {
      return this.env.API_URL_BASE + url;
    }
}
