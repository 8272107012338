import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'format_cents',
    pure: false //TODO Cata: for the future, find a better solution to update only call on change, not on every change detection cycle
})
export class FormatCentsToFloatPipe implements PipeTransform {
    constructor(
    ) {
    }
    transform(key: string | number, addFiatSymbol: boolean = false): string {
        if (!key && key !== 0) {
            return "";
        }

        if (addFiatSymbol) {
            let value = new Intl.NumberFormat("de-AT", {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2,
            }).format(+key / 100);
            return value;
        }

        return "" + (+key / 100);
    }

    transformReverse(key: string | number): number {
        return +parseFloat("" + +key * 100).toFixed(2);
    }
}