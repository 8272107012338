import { Route } from '@angular/router';
import {LoginPageComponent} from "./components/login-page/login-page.component";
import {RegisterComponent} from "./components/register/register.component";
import {RegisterConfirmComponent} from "./components/register-confirm/register-confirm.component";
import {RegisterActivateComponent} from "./components/register-activate/register-activate.component";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {ForgotPasswordActivateComponent} from "./components/forgot-password-activate/forgot-password-activate.component";
import { ExtraUserInvitationActivateComponent } from './components/extra-user-invitation-activate/extra-user-invitation-activate.component';

export const appRoutes: Route[] = [
    { path: '', redirectTo: 'login', pathMatch: 'full'},
    { path: 'login', component: LoginPageComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'register-business', component: RegisterComponent },
    { path: 'register-confirm', component: RegisterConfirmComponent },
    { path: 'register-activate', component: RegisterActivateComponent },
    { path: 'register-activate/:code', component: RegisterActivateComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'forgot-password-activate', component: ForgotPasswordActivateComponent },
    { path: 'forgot-password-activate/:code', component: ForgotPasswordActivateComponent },
    { path: 'kitchen-user-register/:code', component: ExtraUserInvitationActivateComponent },
];
