<div class="entire-host-container"
    [ngClass]="{
        'r-usage-entire-host-container' : (usage === AddressUsageEnum.RESTAURANT) && !(isExtended$() | async)?.value
    }">

    <div id="overallBackground" class="overall" [@inOutBoxAnimation] [@.disabled]="usage === AddressUsageEnum.RESTAURANT"
    (mouseup)="onBackgroundDismiss($event)"
    [ngClass]="{
        'r-usage-overall' : usage === AddressUsageEnum.RESTAURANT,
        'r-usage-overall-mr-top' : usage === AddressUsageEnum.RESTAURANT && !(isExtended$() | async)?.value
    }">
        <div class="address-editor-box" 
                [ngClass]="{
                    'extended' : (isExtended$() | async)?.value,
                    'r-usage-editor-box' : usage === AddressUsageEnum.RESTAURANT,
                    'isCompactExtended' : ((canShowExtendedStep2$ | async) && (isCompactVersion$ | async) && (isExtended$() | async)?.value)
                }">

            <!-- 
                Step A:  Search bar and existing addresses list 
            -->
            <div class="left-side" *ngIf="(canShowExtendedStep1$ | async)" [ngClass]="{'left-side-compact' : (isCompactVersion$ | async)}">

                <!-- Search functionality -->
                <div class="address-search-container">

                    <!-- Input field container -->
                    <div class="input-field-container">
                        <!-- Search icon -->
                        <ng-container *ngIf="!(isExtended$() | async)?.value; else backIcon">
                            <div class="address-search-icon">
                                <img [src]="'./libs/shared-ui/assets/images/search-magnifying-glass.svg'" alt="Search for food"/>
                            </div>
                        </ng-container>

                        <!-- Back icon -->
                        <ng-template #backIcon>
                            <div class="address-back-icon" (click)="onBackClick()">
                                <img [src]="'./libs/shared-ui/assets/images/back-arrow-circle-filled.svg'" alt="Back to searching" title="{{ translations?.address_image_title_back_search || ''}}"/>
                            </div>
                        </ng-template>


                        <!-- Input field -->
                        <input #mapSearchField class="search-input" [disabled]="(isExtended$() | async)?.value"
                            (input)="inputValueChanged()" type="text" placeholder="{{ translations?.search_address_placeholder || ''}}">

                        <!-- Clear text (x) icon -->
                        <ng-container *ngIf="isClearButtonVisible$() | async">
                            <div class="address-clear-text-icon" (click)="onClearClick()">
                                <img [src]="'./libs/shared-ui/assets/images/clear-text-round-x.svg'" alt="Clear food search" title="{{ translations?.address_image_title_clear_input || ''}}"/>
                            </div>
                        </ng-container>
                    </div>

                    <!-- Current browser address - geo-targeting -->
                    <div *ngIf="!(isExtended$() | async)?.value" class="address-current-target" (click)="onBrowserLocationClick()">
                        <img [src]="'./libs/shared-ui/assets/images/nav-target-location.svg'" class="address-current-target-img" alt="Use browser address location" title="{{ translations?.address_image_title_browser_location || ''}}"/>
                    </div>
                </div>

                <!-- Existing addresses list -->
                <ng-container *ngIf="!(isExtended$() | async)?.value && (addressList) as list">
                    <ng-container *ngIf="list.length > 0">
                        <div class="saved-addresses-container" [ngClass]="{'extra-padding': (seeAllExtended$ | async)}">

                            <!-- Title -->
                            <div class="saved-address-title">{{ translations?.address_stored_title || ''}}</div>

                            <!-- List -->
                            <div *ngFor="let address of ((seeAllExtended$ | async) ? list : list.slice(0, 5))" class="saved-address-item">

                                <div class="address-highlight-container" [ngClass]="
                                    {'background-highlight' : address.getIsDefault(), 'selected' : address.getIsDefault(),
                                    'r-usage-selected' : address.getIsDefault() && usage === AddressUsageEnum.RESTAURANT}"
                                        (mousedown)="onExistingAddressSelect(address)">

                                    <!-- Default star -->
                                    <div class="default-address-icon">
                                        <ng-container *ngIf="address.getIsDefault()">
                                            <img class="default-address-img" [src]="'./libs/shared-ui/assets/images/default-address-star.svg'" alt="Default delivery address" title="{{ translations?.address_image_title_default || ''}}"/>
                                        </ng-container>
                                    </div>

                                    <!-- Address -->
                                    <div class="stored-address-content" [ngClass]="{'address-text-selected' : address.getIsDefault()}">
                                        {{ address.getStreetName() }} {{ address.getStreetNumber() }}, {{ address.getCity() }}, {{ address.getPostalCode() }}
                                    </div>
                                </div>

                                <!-- Edit button -->
                                <div class="address-edit-button" (click)="onEditAddress(address)">
                                    <img class="edit-address-img" [src]="'./libs/shared-ui/assets/images/address-edit.svg'" alt="Edit delivery address" title="{{ translations?.address_image_title_edit || ''}}"/>
                                </div>

                                <!-- Delete button -->
                                <div class="address-delete-button" *ngIf="usage !== AddressUsageEnum.RESTAURANT" (click)="onDeleteAddressClick(address)">
                                    <img class="delete-address-img" [src]="'./libs/shared-ui/assets/images/delete-address-trash-bin-gray.svg'" alt="Delete address" title="{{ translations?.address_image_title_delete || ''}}"/>
                                </div>

                            </div>

                            <!-- More -->
                            <ng-container *ngIf="(list.length > 5) && ((seeAllExtended$ | async) === false)">
                                <div class="see-all-addresses-button" (click)="onToggleSeeAll()">
                                    {{ translations?.address_stored_see_all || ''}}                                    
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>

                <!-- 
                    Step B:  Edit of the input fields (1/2) and google map (2/2)
                -->

                <!-- Extended part 1 / 2 -->
                <!-- Address details input -->
                <ng-container *ngIf="(isExtended$() | async)?.value">
                    <div class="address-edit-container">
                        <!-- Street, number -->
                        <div class="edit-details-row-1">
                            <fs-input [label]="translations?.address_extended_street || ''" [style]="'full-width'" class="row-1-item-street"
                                    [inputValue]="getTempAddress().getStreetName()" (enterPress)="submitClick()"
                                    (valueUpdate)="streetUpdate($event)"></fs-input>

                            <fs-input [label]="translations?.address_extended_street_number || ''" [style]="'full-width'" class="row-1-item-number"
                                    [inputValue]="getTempAddress().getStreetNumber()" (enterPress)="submitClick()"
                                    (valueUpdate)="streetNumberUpdate($event)"></fs-input>
                        </div>

                        <!-- Entrance, floor, door -->
                        <div class="edit-details-row-2">
                            <div class="row-2-entrance-floor">
                                <fs-input [label]="translations?.address_extended_entrance || ''" [style]="'full-width'" class="row-2-item-entrance"
                                        [inputValue]="getTempAddress().getEntrance()" (enterPress)="submitClick()"
                                        (valueUpdate)="entranceUpdate($event)"></fs-input>

                                <fs-input [label]="translations?.address_extended_floor || ''" [style]="'full-width'" class="row-2-item-floor"
                                        [inputValue]="getTempAddress().getFloor()" (enterPress)="submitClick()"
                                        (valueUpdate)="floorUpdate($event)"></fs-input>
                            </div>

                            <fs-input [label]="translations?.address_extended_door || ''" [style]="'full-width'" class="row-2-item"
                                    [inputValue]="getTempAddress().getDoorNumber()" (enterPress)="submitClick()"
                                    (valueUpdate)="doorUpdate($event)"></fs-input>
                        </div>

                        <!-- City, zipcode -->
                        <div class="edit-details-row-3">
                            <fs-input [label]="translations?.address_extended_postal_code || ''" [style]="'full-width'" class="row-3-item"
                                    [inputValue]="getTempAddress().getPostalCode()" (enterPress)="submitClick()"
                                    (valueUpdate)="postalCodeUpdate($event)"></fs-input>

                            <div class="row-3-city-country">
                                <ng-container *ngIf="!!getTempAddress().getDistrict()">{{  getTempAddress().getDistrict() + ", "}}
                                </ng-container>{{ getTempAddress().getCity() }}, {{ getTempAddress().getCountry() }}
                            </div>
                        </div>

                        <!-- Other details -->
                        <div class="edit-details-more">
                            <div class="more-details-title"> {{ translations?.address_extended_more || ''}} </div>
                            <fs-input [label]="translations?.address_extended_instructions || ''" [style]="'full-width'" class="row-more-details"
                                    [inputValue]="getTempAddress().getAdditionalInfo()" (enterPress)="submitClick()"
                                    (valueUpdate)="additionalInfoUpdate($event)"></fs-input>


                        </div>

                        <!-- Delete address button -->
                        <ng-container *ngIf="(!(getTempAddress$() | async)?.getIsTemporary() || (getTempAddress$() | async)?.getIsLocallyStored()) 
                                                && usage !== AddressUsageEnum.RESTAURANT">
                            <div class="address-delete-long">
                                <div class="address-delete-action" (click)="onDeleteAddressClick(getTempAddress())">
                                    <div class="address-delete-long-icon">
                                        <img class="edit-address-img" [src]="'./libs/shared-ui/assets/images/delete-address-trash-bin.svg'" alt="Delete address"/>
                                    </div>
                                    <div class="address-delete-long-text">
                                        {{ translations?.address_extended_delete || ''}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Confirm button -->
                        <!-- This is for both part 1/2 + 2/2 visible together in wide screen-->
                        <fs-button [text]="(getTempAddress$() | async)?.getIsTemporary() &&
                                            (getTempAddress$() | async)?.hasGeneratedId() &&
                                            !(getTempAddress$() | async)?.getIsLocallyStored()
                                    ? translations?.address_extended_button_confirm || '' : translations?.address_extended_button_save_changes || ''"
                                [style]="'full-width-large-height'" class="confirm-button"
                                [type]="'raised-primary'" (onPress)="confirmAddressClick()"
                                class="confirm-button"
                                *ngIf="(canShowExtendedStep1$ | async) && (canShowExtendedStep2$ | async)">
                        </fs-button>

                        <!-- Mobile-only button -->
                        <!-- Continue button for the part 1/2 -->
                        <fs-button [text]="'Continue'"
                                *ngIf="(canShowExtendedStep1$ | async) && ((canShowExtendedStep2$ | async) === false)"
                                [style]="'full-width-large-height'" class="confirm-button"
                                [type]="'raised-primary'" (onPress)="confirmMobileAddressClick()"
                                class="confirm-button"></fs-button>

                    </div>
                </ng-container>
            </div>

            <!-- Extended part 2 / 2 -->
            <!-- Map functionality-->
            <div class="right-side" *ngIf="(isExtended$() | async)?.value  && (canShowExtendedStep2$ | async)" [ngClass]="{'isCompact' : (isCompactVersion$ | async)}">

                <!-- All data and back -->
                <ng-container *ngIf="(isCompactVersion$ | async)">
                    <div class="compact-extended-top-address">
                        <div class="compact-extended-back" (click)="onBackCompactExtended()">
                            <img [src]="'./libs/shared-ui/assets/images/back-arrow-circle-filled.svg'" alt="Back to searching" title="{{ translations?.address_image_title_back_search || ''}}"/>
                        </div>
                        <div class="address-to-confirm-compact">
                            <span class="address-to-confirm-compact-text-1">
                            {{ 
                                getTempAddress().getStreetName() + " " +
                                getTempAddress().getStreetNumber()
                                
                            }}
                            </span>
                            <span class="address-to-confirm-compact-text-2">
                            {{
                                ((getTempAddress().doorNumber || getTempAddress().entrance || getTempAddress().floor) ? (
                                    " (" +
                                    (getTempAddress().doorNumber ? (translations?.address_extended_door + ": " + getTempAddress().doorNumber + " ") : '') + 
                                    (getTempAddress().entrance ? (translations?.address_extended_entrance + ": " + getTempAddress().entrance + " ") : '') + 
                                    (getTempAddress().floor ? (translations?.address_extended_floor + ": " + getTempAddress().floor) : '')
                                    + ")"
                                ) : '')
                            }}
                            </span>
                            <span class="address-to-confirm-compact-text-3">
                            {{
                                (getTempAddress().getPostalCode() ? getTempAddress().getPostalCode() : '') + " " +
                                getTempAddress().getCity()
                            }}
                            </span>
                        </div>
                    </div>
                </ng-container>

                <!-- Map -->
                <div class="map-container" [ngClass]="{'isCompact' : (isCompactVersion$ | async)}">
                    <ng-container *ngIf="(isCompactVersion$ | async); else regularMap">
                        <google-map [options]="((getTempAddress$() | async) | google_map_format_converter) ?? defaultMapOptions"
                                (mapClick)="onMapClick($event)" (mapInitialized)="onMapReady($event)" #googleMapContainer
                                [width]="((screenWidth$ | async) || 0) < 540 ? (((screenWidth$ | async) || 0) - 50) : 500"
                                [height]="((screenHeight$ | async) || 0) < 600 ? (((screenHeight$ | async) || 0) - 200) : 400">
                        </google-map>
                    </ng-container>
                    <ng-template #regularMap>
                        <google-map [options]="((getTempAddress$() | async) | google_map_format_converter) ?? defaultMapOptions"
                                (mapClick)="onMapClick($event)" (mapInitialized)="onMapReady($event)" #googleMapContainer>
                        </google-map>
                    </ng-template>                    
                </div>

                <!-- Mobile-only button -->
                <!-- Confirm 2/2 -->
                <fs-button [text]="translations?.address_extended_button_confirm || ''"
                        *ngIf="((canShowExtendedStep1$ | async) === false) && ((canShowExtendedStep2$ | async))"
                        [style]="'full-width-large-height'" class="confirm-button"
                        [type]="'raised-primary'" (onPress)="confirmAddressClick()"
                        class="mt-3"></fs-button>
            </div>
        </div>
    </div>

    <!-- Background -->
    <div *ngIf="usage !== AddressUsageEnum.RESTAURANT"   class="background" [@inOutBackgroundAnimation]></div>

</div>
