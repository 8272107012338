import {v4 as uuidv4} from "uuid";

export class AddressResponse {
    public id: string = "";
    public latitude: number = 0;
    public longitude: number = 0;
    public postalCode: string = "";
    public district: string = ""; // eg: Bezirk
    public city: string = "";
    public state: string = "";
    public country: string = "";
    public streetName: string = "";
    public streetNumber: string = "";
    public entrance: string = "";
    public floor: string = "";
    public doorNumber: string = "";
    public additionalInfo: string = "";
    public isDefault: boolean = false;
    public isTemporary: boolean = false;
    public isLocallyStored: boolean = false; // in localStorage

    public constructor(isTemporary: boolean = false) {
        this.setIsTemporary(isTemporary);
        if (isTemporary) {
            let tempId = "temp-" + Date.now().toString() + "-" + uuidv4();
            this.setId(tempId);
        }
    }

    public getId(): string {
        return this.id;
    }

    public getLatitude(): number {
        return this.latitude;
    }

    public setLatitude(l: number) {
        this.latitude = l;
    }

    public getLongitude(): number {
        return this.longitude;
    }

    public setLongitude(l: number) {
        this.longitude = l;
    }

    public getPostalCode(): string {
        return this.postalCode;
    }

    public setPostalCode(s: string) {
        this.postalCode = s;
    }

    public getCity(): string {
        return this.city;
    }

    public setCity(s: string) {
        this.city = s;
    }

    public getCountry(): string {
        return this.country;
    }

    public setCountry(s: string) {
        this.country = s;
    }

    public getState(): string {
        return this.state;
    }

    public setState(s: string) {
        this.state = s;
    }

    public getDistrict(): string {
        return this.district;
    }

    public setDistrict(s: string) {
        this.district = s;
    }

    public getStreetName(): string {
        return this.streetName;
    }

    public setStreetName(s: string) {
        this.streetName = s;
    }

    public getStreetNumber(): string {
        return this.streetNumber;
    }

    public setStreetNumber(s: string) {
        this.streetNumber = s;
    }

    public getEntrance(): string {
        return this.entrance;
    }

    public setEntrance(s: string) {
        this.entrance = s;
    }

    public getFloor(): string {
        return this.floor;
    }

    public setFloor(s: string) {
        this.floor = s;
    }

    public getDoorNumber(): string {
        return this.doorNumber;
    }

    public setDoorNumber(s: string) {
        this.doorNumber = s;
    }

    public getAdditionalInfo(): string {
        return this.additionalInfo;
    }

    public setAdditionalInfo(s: string) {
        this.additionalInfo = s;
    }

    public getIsDefault(): boolean {
        return this.isDefault;
    }

    public setIsDefault(b: boolean) {
        this.isDefault = b;
    }

    public getIsTemporary(): boolean {
        return this.isTemporary;
    }

    public hasGeneratedId(): boolean {
        return this.getId().includes("temp-");
    }

    public getIsLocallyStored(): boolean {
        return this.isLocallyStored;
    }

    public setIsTemporary(b: boolean) {
        this.isTemporary = b;
    }

    public setIsLocallyStored(b: boolean) {
        this.isLocallyStored = b;
    }

    public setId(s: string) {
        this.id = s;
    }

    public isEmpty(): boolean {
        return !((this.streetName + this.streetNumber + this.city).length > 0 && this.latitude > 0 && this.longitude > 0);
    }
}