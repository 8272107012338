import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { BaseLoadingService } from './base-loading';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService extends BaseLoadingService{

  constructor(private titleService: Title) { 
    super();    
    this.setFinished();
  }

    public setTitle(value: string) {
        this.titleService.setTitle(value);
    }
}
