import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'fs-textarea',
  templateUrl: './fs-textarea.component.html',
  styleUrls: ['./fs-textarea.component.scss'],
})

export class FsTextareaComponent {

    @Input() label = "";
    @Output() valueUpdate: EventEmitter<string> = new EventEmitter<string>();
    private _inputValue: string = "";

    @Input()
    public set inputValue(s: string) {
        this._inputValue = s;
        this.valueUpdate.emit(s);
    }
    public get inputValue(): string {
        return this._inputValue;
    }

}
