<div class="language-menu">
    <language-selector></language-selector>
</div>

<div class="logo-container">
    <div class="logo">
        <account-logo></account-logo>
    </div>
</div>

<div class="main-container">
    <div class="flex mb-4">
        {{ 'login_description' | translate}}
    </div>

    <div class="input-container">
        <div class="flex">
            <fs-input [label]="'login_email_label' | translate"
                      [style]="'full-width'" (valueUpdate)="emailUpdate($event)"
                      (enterPress)="submitClick()"></fs-input>
        </div>

        <div class="flex">
            <fs-input [label]="'login_password_label' | translate"
                      [style]="'full-width'" [type]="passInputType"
                      (valueUpdate)="passwordUpdate($event)" (enterPress)="submitClick()"
                      [trailingIcon]="trailingIcon"
                      [trailingIconFade]="passTrailingIconFade"
                      (trailingIconPress)="onPasswordIconPress()">
            </fs-input>
        </div>

        <!-- Disabled for now until further discussion -->
        <!--<div class="flex remember-me">
            <fs-checkbox [text]="'login_checkbox_remember' | translate"></fs-checkbox>
        </div>-->

        <div class="flex">
            <fs-button [text]="'login_sign_in_button' | translate" [style]="'full-width-large-height'"
                       [type]="'raised-primary'" (onPress)="submitClick()"></fs-button>
        </div>

        <div class="flex mt-10">
            <fs-link [url]="'forgot-password'" [text]="'login_forgot_password' | translate"></fs-link>
        </div>

        <div class="flex mt-7">
            <p>{{ 'login_sign_up_invite' | translate }}</p>
        </div>
        <div class="flex mt-3">
            <fs-button [text]="'login_sign_up_button' | translate" [url]="registerURL"
                       [style]="'full-width-large-height'" [type]="'raised'"
                       [leadingIconUrl]="'account-sign-up.svg'"></fs-button>
        </div>
    </div>
</div>


