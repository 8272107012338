import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Product} from "../../../../shared-models/src/lib/product-type";

@Component({
  selector: 'fs-logo',
  templateUrl: './fs-logo.component.html',
  styleUrls: ['./fs-logo.component.scss'],
})
export class FsLogoComponent {

    private readonly BASE_NAME: string = "logo-foodis";
    public logoName = this.BASE_NAME;

    @Input() set whiteVersion(b: boolean) {
        if (b) {
            this.logoName = this.BASE_NAME + "-white";
        }
    }

    @Input() set type(t: Product) {
        if (this.whiteVersion) {
            this.logoName = this.BASE_NAME + "-white";
        } else {
            this.logoName = (t === Product.UNDEFINED || t === Product.USER_APP || t === Product.LADING_PAGE) ? this.BASE_NAME : this.BASE_NAME + "-" + t;
        }
        
    }

    @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

    public pressed() {
        this.onPress.emit();
    }
}