import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
  selector: 'fs-input',
  templateUrl: './fs-input.component.html',
  styleUrls: ['./fs-input.component.scss'],
})

export class FsInputComponent implements AfterViewInit {

    @Input() label = "";
    @Input() type: string = "text"; // 'color' | 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week';
    @Input() style: string = "normal";
    @Input() floatLabel: FloatLabelType = "auto";
    @Input() prefixIcon: string = "";
    @Input() prefixIconFade: boolean = false;
    @Input() trailingIcon: string = "";
    @Input() trailingIconFade: boolean = false;
    @Input() keepTrailingIconSpace: boolean = false; // specifically adds an empty space container (from CSS) 48x48 so the input border doesn't enlarge after it gets visible
    

    @Input() disabled: boolean = false;
    @Input() appearanceStyle: 'fill' | 'outline' = "fill" // fill is gray background, outline is white
    @Input() subscriptSizing: 'fixed' | 'dynamic' = "fixed" // 'fixed' - has space underneath always | 'dynamic' is adaptable. starts with 0 space under the input
    

    @Output() valueUpdate: EventEmitter<string> = new EventEmitter<string>();
    @Output() enterPress: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() prefixIconPress: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() trailingIconPress: EventEmitter<boolean> = new EventEmitter<boolean>();    
    @Output() onKeyUp: EventEmitter<string> = new EventEmitter<string>();

    private _inputValue: string = "";

    @Input()
    public set inputValue(s: string) {
        this._inputValue = s;
        this.valueUpdate.emit(s);
    }
    public get inputValue(): string {
        return this._inputValue;
    }

    constructor(
        private cdRef: ChangeDetectorRef
    ) {
    }

    public ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    public onEnterPress() {
        this.enterPress.emit();
    }

    public onPrefixIconPress() {
        this.prefixIconPress.emit();
    }

    public onTrailingIconPress() {
        this.trailingIconPress.emit();
    }

    public keyUp($event: any) {
        this.onKeyUp.emit($event);
    }
}
