// Default coordinates
export const mapViennaOptionsCenter = { lat:  48.2075552, lng: 16.3876271 };
export const mapViennaOptionsZoom = 16;

// Default values
export const mapViennaOptions: google.maps.MapOptions = {
    center: mapViennaOptionsCenter,
    zoom: mapViennaOptionsZoom,
    disableDefaultUI: false
};


/*
    Used to check when the user does a Drag & Drop with the pinpoint on the map.
    In case the distance is bigger than the threshold, then we re-trigger an address fetch / refill in the inputs
    We want the user to do small adjustment on the map. But if the adjustment is big enough, then we want to make sure we accurately get the new address
*/
export const DISTANCE_PINPOINT_THRESHOLD = 0.04; // 40 meters.


/*
     Vienna Districts (Bezirk) coordinates
*/
export interface CityDistrict {
    name: string; // Brigitteanu
    postalCode: string; // 1200
    actualNumber: number; // 20
    coordinatePoints: any[]; // [[48.236275, 16.361539], [... , ...], ...]
    state: DistrictState;
}

export enum DistrictState {
    ACTIVE = "Active",
    SOON = "Soon",
    NOT_STARTED = "Not started"
}

export enum DistrictStateFillColor {
    ACTIVE = "#6DB718",
    SOON = "#ffca00",
    NOT_STARTED = "#c0c0c0"
}

export const BRIGITTENAU = {
    name: "Brigittenau",
    postalCode: "1200",
    actualNumber: 20,
    state: DistrictState.ACTIVE,
    center: [48.235123, 16.379828],
    coordinatePoints: [
        [48.261321, 16.368286],
        [48.262688, 16.372403],
        [48.236311, 16.398146],
        [48.230333, 16.386842],
        [48.229342, 16.387883],
        [48.227991, 16.388102],
        [48.225609, 16.383247],
        [48.228272, 16.380530],
        [48.229509, 16.377497],
        [48.227806, 16.372059],
        [48.226067, 16.370405],
        [48.225683, 16.369540],
        [48.225097, 16.367431],
        [48.227078, 16.365398],
        [48.229059, 16.363183],
        [48.232819, 16.361256],
        [48.236973, 16.361878],
        [48.242116, 16.365079],
        [48.248522, 16.370678],
        [48.251685, 16.371877],
        [48.257837, 16.369024],
        [48.261321, 16.368286]
    ]
}

export const DOBLING = {
    name: "Döbling",
    postalCode: "1190",
    actualNumber: 19,
    state: DistrictState.NOT_STARTED,
    center: [48.246689, 16.343497],
    coordinatePoints: [
        [48.262776, 16.372246],
        [48.261346, 16.368276],
        [48.256229, 16.369648],
        [48.252384, 16.371723],
        [48.248600, 16.370694],
        [48.240669, 16.363892],
        [48.236295, 16.361564],
        [48.236102, 16.356710],
        [48.232316, 16.355177],
        [48.232016, 16.350377],
        [48.233687, 16.350792],
        [48.233161, 16.347331],
        [48.233922, 16.337881],
        [48.237098, 16.337759],
        [48.237086, 16.330291],
        [48.240192, 16.319966],
        [48.240916, 16.321315],
        [48.241815, 16.316705],
        [48.243718, 16.310745],
        [48.244967, 16.309150],
        [48.245717, 16.307725],
        [48.247094, 16.307515],
        [48.251118, 16.287821],
        [48.253183, 16.287043],
        [48.253922, 16.285933],
        [48.257702, 16.285359],
        [48.261964, 16.290250],
        [48.266539, 16.292144],
        [48.266784, 16.286407],
        [48.268080, 16.287019],
        [48.272155, 16.294254],
        [48.274118, 16.314679],
        [48.278193, 16.321525],
        [48.278309, 16.330448],
        [48.285568, 16.345141],
        [48.289086, 16.341969],
        [48.290308, 16.348592],
        [48.283087, 16.355381],
        [48.262861, 16.372356],
    ]
}

export const WAHRING = {
    name: "Währing",
    postalCode: "1180",
    actualNumber: 18,
    state: DistrictState.NOT_STARTED,
    center: [48.230328, 16.338641],
    coordinatePoints: [
        [48.232111, 16.353372],
        [48.231991, 16.353715],
        [48.230503, 16.350711],
        [48.229516, 16.350289],
        [48.223382, 16.348764],
        [48.221760, 16.343710],
        [48.217300, 16.341939],
        [48.217709, 16.338942],
        [48.219672, 16.339691],
        [48.220375, 16.336370],
        [48.220772, 16.336693],
        [48.221147, 16.335842],
        [48.220782, 16.336587],
        [48.221124, 16.335767],
        [48.222443, 16.336791],
        [48.222616, 16.336536],
        [48.222847, 16.336723],
        [48.225136, 16.332088],
        [48.225322, 16.331183],
        [48.226471, 16.328604],
        [48.227159, 16.328687],
        [48.227765, 16.327539],
        [48.227206, 16.326815],
        [48.226523, 16.324746],
        [48.226769, 16.322338],
        [48.228228, 16.323066],
        [48.228467, 16.321627],
        [48.229287, 16.321893],
        [48.230033, 16.316317],
        [48.231491, 16.316652],
        [48.231618, 16.315658],
        [48.232573, 16.315591],
        [48.232229, 16.311557],
        [48.232726, 16.309120],
        [48.234120, 16.305507],
        [48.235152, 16.302391],
        [48.236486, 16.302144],
        [48.236458, 16.301063],
        [48.237219, 16.300292],
        [48.237750, 16.299094],
        [48.237819, 16.296825],
        [48.237184, 16.296188],
        [48.236666, 16.294163],
        [48.239647, 16.294259],
        [48.239438, 16.288607],
        [48.240607, 16.283860],
        [48.241186, 16.284199],
        [48.242480, 16.289361],
        [48.243253, 16.289429],
        [48.243818, 16.290628],
        [48.244453, 16.291175],
        [48.244318, 16.291971],
        [48.244724, 16.294063],
        [48.245867, 16.293345],
        [48.245920, 16.282590],
        [48.247650, 16.282608],
        [48.250888, 16.288524],
        [48.249070, 16.297356],
        [48.248464, 16.303305],
        [48.246911, 16.307696],
        [48.245541, 16.307503],
        [48.245229, 16.309239],
        [48.244685, 16.309137],
        [48.243132, 16.311801],
        [48.241596, 16.316609],
        [48.241811, 16.317043],
        [48.240819, 16.321316],
        [48.240133, 16.319843],
        [48.239561, 16.322916],
        [48.237061, 16.330397],
        [48.237040, 16.337715],
        [48.233812, 16.337744],
        [48.233821, 16.342282],
        [48.233135, 16.347424],
        [48.233164, 16.349089],
        [48.233841, 16.349295],
        [48.233664, 16.350871],
        [48.231986, 16.350518],
        [48.232143, 16.353332],
    ]
}

export const HERNALS = {
    name: "Hernals",
    postalCode: "1170",
    actualNumber: 17,
    state: DistrictState.NOT_STARTED,
    center: [48.225187, 16.317177],
    coordinatePoints: [
        [48.217123, 16.341721],
        [48.212314, 16.339159],
        [48.212314, 16.338474],
        [48.215079, 16.338654],
        [48.213334, 16.325831],
        [48.215402, 16.325362],
        [48.215354, 16.322403],
        [48.216136, 16.322612],
        [48.216207, 16.320884],
        [48.217325, 16.321220],
        [48.217475, 16.319952],
        [48.220556, 16.320926],
        [48.223421, 16.312764],
        [48.222681, 16.312107],
        [48.223327, 16.308448],
        [48.222744, 16.308229],
        [48.222639, 16.306822],
        [48.224504, 16.306290],
        [48.224661, 16.302396],
        [48.223650, 16.302912],
        [48.223452, 16.301270],
        [48.224662, 16.297302],
        [48.223559, 16.291221],
        [48.221713, 16.287892],
        [48.221627, 16.286070],
        [48.224191, 16.286869],
        [48.223819, 16.284266],
        [48.225963, 16.280417],
        [48.226954, 16.276307],
        [48.226446, 16.266247],
        [48.224773, 16.258939],
        [48.219285, 16.249864],
        [48.219347, 16.249455],
        [48.227276, 16.255591],
        [48.230298, 16.257191],
        [48.237854, 16.269259],
        [48.237495, 16.266061],
        [48.239972, 16.256744],
        [48.242994, 16.257023],
        [48.245956, 16.258821],
        [48.245508, 16.261176],
        [48.248281, 16.265340],
        [48.249276, 16.264778],
        [48.251964, 16.270540],
        [48.252900, 16.270603],
        [48.254074, 16.269070],
        [48.254040, 16.267371],
        [48.254584, 16.264267],
        [48.254924, 16.264216],
        [48.255122, 16.270100],
        [48.256993, 16.275542],
        [48.256712, 16.280000],
        [48.258269, 16.285493],
        [48.256253, 16.285161],
        [48.255471, 16.285953],
        [48.253098, 16.286617],
        [48.251133, 16.287576],
        [48.250950, 16.288374],
        [48.250032, 16.286605],
        [48.247547, 16.282398],
        [48.245830, 16.282468],
        [48.245775, 16.293278],
        [48.244726, 16.293849],
        [48.243298, 16.289364],
        [48.242490, 16.289350],
        [48.241071, 16.283749],
        [48.240440, 16.283847],
        [48.239382, 16.288765],
        [48.239521, 16.294142],
        [48.236627, 16.294170],
        [48.237759, 16.296928],
        [48.237619, 16.299199],
        [48.236348, 16.301233],
        [48.236385, 16.302055],
        [48.235040, 16.302358],
        [48.235059, 16.302600],
        [48.234377, 16.304374],
        [48.234115, 16.305419],
        [48.233647, 16.306540],
        [48.233155, 16.308380],
        [48.232694, 16.309026],
        [48.232394, 16.310160],
        [48.232170, 16.311810],
        [48.232523, 16.315563],
        [48.231589, 16.315602],
        [48.231451, 16.316606],
        [48.229980, 16.316198],
        [48.229246, 16.321869],
        [48.228430, 16.321579],
        [48.228191, 16.323016],
        [48.226746, 16.322312],
        [48.226549, 16.324973],
        [48.227687, 16.327629],
        [48.227105, 16.328655],
        [48.226433, 16.328583],
        [48.225303, 16.331175],
        [48.225057, 16.332172],
        [48.222832, 16.336685],
        [48.222578, 16.336496],
        [48.222425, 16.336753],
        [48.221097, 16.335704],
        [48.220746, 16.336568],
        [48.220339, 16.336243],
        [48.219641, 16.339602],
        [48.217682, 16.338846],
        [48.217230, 16.341967],        
    ]
}

export const OTTAKRING = {
    name: "Ottakring",
    postalCode: "1160",
    actualNumber: 16,
    state: DistrictState.NOT_STARTED,
    center: [48.212541, 16.312125],
    coordinatePoints: [
        [48.212197, 16.338356],
        [48.212118, 16.339209],
        [48.208755, 16.338493],
        [48.206336, 16.336928],
        [48.204317, 16.336865],
        [48.204171, 16.333742],
        [48.207398, 16.312689],
        [48.204504, 16.312471],
        [48.204567, 16.311502],
        [48.203047, 16.310659],
        [48.203214, 16.309128],
        [48.203713, 16.309128],
        [48.204796, 16.303631],
        [48.205452, 16.298864],
        [48.207378, 16.293852],
        [48.206429, 16.288971],
        [48.206878, 16.284664],
        [48.207584, 16.285967],
        [48.210145, 16.285647],
        [48.212368, 16.285989],
        [48.212971, 16.285371],
        [48.213986, 16.285404],
        [48.214941, 16.281284],
        [48.215102, 16.275144],
        [48.217480, 16.270144],
        [48.217619, 16.264647],
        [48.219579, 16.259888],
        [48.221475, 16.253748],
        [48.224741, 16.258361],
        [48.226637, 16.268874],
        [48.226862, 16.276381],
        [48.225330, 16.281477],
        [48.223799, 16.284194],
        [48.224141, 16.286734],
        [48.221539, 16.285994],
        [48.221668, 16.288164],
        [48.223478, 16.291299],
        [48.224591, 16.297343],
        [48.223360, 16.301539],
        [48.223595, 16.302793],
        [48.224623, 16.302568],
        [48.224399, 16.306153],
        [48.222533, 16.306743],
        [48.222705, 16.312128],
        [48.223362, 16.312759],
        [48.220469, 16.320813],
        [48.217397, 16.319815],
        [48.217260, 16.321099],
        [48.216132, 16.320747],
        [48.216026, 16.322475],
        [48.215200, 16.322236],
        [48.215290, 16.325318],
        [48.213182, 16.325639],
        [48.214211, 16.331583],
        [48.215060, 16.338549],
    ]
}

export const RUDOLFSTEIN_FUNFHAUS = {
    name: "Rudolfsheim-Fünfhaus",
    postalCode: "1150",
    actualNumber: 15,
    state: DistrictState.NOT_STARTED,
    center: [48.198763, 16.328128],
    coordinatePoints: [
        [48.204162, 16.336977],
        [48.201458, 16.337332],
        [48.195256, 16.339502],
        [48.190846, 16.337460],
        [48.188344, 16.339012],
        [48.187441, 16.337280],
        [48.185324, 16.335277],
        [48.183965, 16.332354],
        [48.183844, 16.327608],
        [48.187153, 16.314274],
        [48.188416, 16.314509],
        [48.190304, 16.315501],
        [48.190040, 16.320463],
        [48.190786, 16.320535],
        [48.192289, 16.316331],
        [48.193492, 16.316981],
        [48.194466, 16.312849],
        [48.196499, 16.313895],
        [48.199025, 16.314346],
        [48.199145, 16.318604],
        [48.202897, 16.310792],
        [48.204509, 16.311640],
        [48.204292, 16.312560],
        [48.207202, 16.312578],
        [48.204072, 16.333713],
        [48.204162, 16.336977],
    ]
}

export const PENZING = {
    name: "Penzing",
    postalCode: "1140",
    actualNumber: 14,
    state: DistrictState.NOT_STARTED,
    center: [48.198101, 16.295526],
    coordinatePoints: [
        [48.190682, 16.320340],
        [48.189981, 16.320288],
        [48.190262, 16.315323],
        [48.187180, 16.314194],
        [48.187688, 16.304213],
        [48.193450, 16.266975],
        [48.201445, 16.247921],
        [48.204526, 16.234678],
        [48.202105, 16.225909],
        [48.207228, 16.213453],
        [48.202576, 16.207180],
        [48.205167, 16.202056],
        [48.209642, 16.210007],
        [48.213998, 16.198611],
        [48.219885, 16.195607],
        [48.222769, 16.198434],
        [48.223181, 16.185359],
        [48.230655, 16.199494],
        [48.234598, 16.192868],
        [48.256543, 16.203823],
        [48.263895, 16.207268],
        [48.258719, 16.220697],
        [48.248307, 16.240928],
        [48.241659, 16.236953],
        [48.238894, 16.247112],
        [48.239423, 16.259039],
        [48.236834, 16.267874],
        [48.229302, 16.255594],
        [48.222710, 16.252943],
        [48.218884, 16.249144],
        [48.221180, 16.253915],
        [48.213704, 16.285101],
        [48.207404, 16.285896],
        [48.206933, 16.284483],
        [48.207051, 16.293759],
        [48.203636, 16.308689],
        [48.199175, 16.318255],
        [48.199057, 16.314412],
        [48.196525, 16.313926],
        [48.194464, 16.312645],
        [48.193387, 16.316840],
        [48.192388, 16.316327],
    ]
}

export const ALSERGRUND = {
    name: "Alsergrund",
    postalCode: "1090",
    actualNumber: 9,
    state: DistrictState.NOT_STARTED,
    center: [48.225050, 16.356090],
    coordinatePoints: [
        [48.236275, 16.361539],
        [48.232675, 16.361279],
        [48.228358, 16.363898],
        [48.225339, 16.367408],
        [48.222753, 16.367909],
        [48.219857, 16.368522],
        [48.218484, 16.370157],
        [48.213843, 16.360387],
        [48.214660, 16.355949],
        [48.215489, 16.346068],
        [48.215241, 16.341016],
        [48.218583, 16.342669],
        [48.221788, 16.343821],
        [48.223322, 16.348650],
        [48.230498, 16.350786],
        [48.231897, 16.354331],
        [48.233003, 16.355847],
        [48.236098, 16.356776],
        [48.236275, 16.361539],
    ]
}

export const JOSEFSTADT = {
    name: "Josefstadt",
    postalCode: "1080",
    actualNumber: 8,
    state: DistrictState.SOON,
    center: [48.211535, 16.347549],
    coordinatePoints: [
        [48.214474, 16.356201],
        [48.208678, 16.355101],
        [48.206959, 16.355696],
        [48.206370, 16.351420],
        [48.206454, 16.349363],
        [48.208618, 16.338540],
        [48.211644, 16.339216],
        [48.215131, 16.340956],
        [48.215318, 16.346012],
        [48.214650, 16.355756],
    ]
}

export const NEUBAU = {
    name: "Neubau",
    postalCode: "1070",
    actualNumber: 7,
    state: DistrictState.ACTIVE,
    center: [48.202944, 16.348479],
    coordinatePoints: [
        [48.206788, 16.355858],
        [48.202662, 16.361385],
        [48.201354, 16.359760],
        [48.199224, 16.352295],
        [48.197780, 16.348566],
        [48.195867, 16.339289],
        [48.198017, 16.338601],
        [48.201627, 16.337315],
        [48.206002, 16.336966],
        [48.208562, 16.338473],
        [48.206372, 16.349411],
        [48.206296, 16.351428],
        [48.206903, 16.355870],
    ]
}

export const MARIAHILF = {
    name: "Mariahilf",
    postalCode: "1060",
    actualNumber: 6,
    state: DistrictState.NOT_STARTED,
    center: [48.195125, 16.350106],
    coordinatePoints: [
        [48.202608, 16.361528],
        [48.201233, 16.364017],
        [48.199786, 16.366018],
        [48.197248, 16.361316],
        [48.196774, 16.359370],
        [48.196944, 16.359200],
        [48.196031, 16.355522],
        [48.194899, 16.354346],
        [48.193201, 16.354437],
        [48.191781, 16.353908],
        [48.190246, 16.352357],
        [48.189682, 16.350799],
        [48.188689, 16.344485],
        [48.188336, 16.339058],
        [48.189027, 16.338217],
        [48.190976, 16.337283],
        [48.192092, 16.337996],
        [48.195073, 16.339446],
        [48.195830, 16.339204],
        [48.197165, 16.346342],
        [48.199153, 16.352305],
        [48.201240, 16.359699],
        [48.202567, 16.361530],
    ]
}

export const MARGARETEN = {
    name: "Margareten",
    postalCode: "1050",
    actualNumber: 5,
    state: DistrictState.NOT_STARTED,
    center: [48.186327, 16.354060],
    coordinatePoints: [
        [48.196893, 16.359181],
        [48.194837, 16.360874],
        [48.194186, 16.361951],
        [48.193884, 16.361685],
        [48.192152, 16.363928],
        [48.190143, 16.362134],
        [48.188089, 16.364599],
        [48.183840, 16.368786],
        [48.180599, 16.359010],
        [48.179180, 16.349783],
        [48.188437, 16.341927],
        [48.189896, 16.351916],
        [48.192021, 16.354192],
        [48.195066, 16.354545],
        [48.196397, 16.356704],
        [48.196893, 16.359181],
    ]
}

export const WIEDEN = {
    name: "Wieden",
    postalCode: "1040",
    actualNumber: 4,
    state: DistrictState.NOT_STARTED,
    center: [48.193103, 16.370240],
    coordinatePoints: [
        [48.199867, 16.375152],
        [48.199488, 16.375357],
        [48.197837, 16.375031],
        [48.197837, 16.375031],
        [48.192799, 16.378462],
        [48.188239, 16.381141],
        [48.185804, 16.373516],
        [48.185184, 16.373166],
        [48.183930, 16.368824],
        [48.188167, 16.364607],
        [48.190142, 16.362266],
        [48.192191, 16.364060],
        [48.193926, 16.361774],
        [48.194225, 16.362058],
        [48.194947, 16.360888],
        [48.196668, 16.359488],
        [48.197419, 16.361905],
        [48.200765, 16.368336],
        [48.199657, 16.373881],
    ]
}

export const LANDSTRASSE = {
    name: "Landstrasse",
    postalCode: "1030",
    actualNumber: 3,
    state: DistrictState.NOT_STARTED,
    center: [48.198965, 16.393085],
    coordinatePoints: [
        [48.212043, 16.385050],
        [48.213154, 16.388173],
        [48.213039, 16.391076],
        [48.212095, 16.393878],
        [48.209492, 16.395944],
        [48.204296, 16.397546],
        [48.202583, 16.400163],
        [48.200892, 16.406851],
        [48.196469, 16.410585],
        [48.188541, 16.424191],
        [48.186326, 16.431040],
        [48.185909, 16.430745],
        [48.185113, 16.428504],
        [48.186670, 16.421575],
        [48.187198, 16.420443],
        [48.185643, 16.419625],
        [48.185976, 16.417751],
        [48.184880, 16.416873],
        [48.185524, 16.414984],
        [48.183245, 16.412779],
        [48.185118, 16.406260],
        [48.184828, 16.405689],
        [48.185787, 16.404605],
        [48.184813, 16.402698],
        [48.183268, 16.404216],
        [48.179865, 16.400072],
        [48.178472, 16.397640],
        [48.175427, 16.397013],
        [48.175602, 16.395403],
        [48.183887, 16.384716],
        [48.188218, 16.381109],
        [48.192996, 16.378526],
        [48.194955, 16.376900],
        [48.198516, 16.375165],
        [48.199932, 16.375325],
        [48.200449, 16.376866],
        [48.205669, 16.382049],
        [48.209277, 16.384552],
        [48.212043, 16.385050],
    ]
}

export const LEOPOLDSTADT = {
    name: "Leopoldstadt",
    postalCode: "1020",
    actualNumber: 2,
    state: DistrictState.NOT_STARTED,
    center: [48.209350, 16.415542],
    coordinatePoints: [
        [48.236167, 16.398090],
        [48.167186, 16.498229],
        [48.165164, 16.495211],
        [48.188600, 16.423811],
        [48.196390, 16.410540],
        [48.200470, 16.407348],
        [48.201961, 16.403366],
        [48.203190, 16.398726],
        [48.205339, 16.397047],
        [48.210558, 16.395336],
        [48.212173, 16.393777],
        [48.213010, 16.391087],
        [48.213016, 16.387739],
        [48.211794, 16.384630],
        [48.211741, 16.381275],
        [48.212228, 16.377678],
        [48.213672, 16.375131],
        [48.217422, 16.371939],
        [48.220470, 16.368286],
        [48.225008, 16.367694],
        [48.226135, 16.370430],
        [48.227753, 16.372153],
        [48.229460, 16.377543],
        [48.228048, 16.380901],
        [48.225516, 16.383287],
        [48.228002, 16.388218],
        [48.230405, 16.386932],
        [48.236167, 16.398090],
    ]
}


export const INNERE_STADT = {
    name: "Innere Stadt",
    postalCode: "1010",
    actualNumber: 1,
    state: DistrictState.NOT_STARTED,
    center: [48.208543, 16.371769],
    coordinatePoints: [
        [48.218422, 16.370156],
        [48.216040, 16.373338],
        [48.213654, 16.375042],
        [48.212222, 16.377607],
        [48.211711, 16.381092],
        [48.211852, 16.384025],
        [48.211551, 16.384528],
        [48.211365, 16.384892],
        [48.208991, 16.384347],
        [48.204304, 16.380906],
        [48.200343, 16.376630],
        [48.199649, 16.373564],
        [48.200744, 16.368720],
        [48.199725, 16.366085],
        [48.201337, 16.364016],
        [48.202730, 16.361502],
        [48.206970, 16.355854],
        [48.208618, 16.355202],
        [48.214387, 16.356485],
        [48.213825, 16.360493],
        [48.217993, 16.369489],
        [48.218422, 16.370156],
    ]
}




export const VIENNA_DISTRICTS = [
    BRIGITTENAU, 
    DOBLING,
    WAHRING,
    HERNALS,
    OTTAKRING,
    RUDOLFSTEIN_FUNFHAUS,
    PENZING,
    ALSERGRUND,
    JOSEFSTADT,
    NEUBAU,
    MARIAHILF,
    MARGARETEN,
    WIEDEN,
    LANDSTRASSE,
    LEOPOLDSTADT,
    INNERE_STADT
] as CityDistrict[]