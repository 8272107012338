<mat-slider 
    class="mat-slider-container" 
    [min]="minValue" 
    [max]="maxValue" 
    [step]="step"     
    [discrete]="false" 
    [displayWith]="formatLabel"
>
    <input matSliderThumb [(ngModel)]="value">
</mat-slider>
