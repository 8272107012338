
/*
    Official documentation: Google - https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
    Calculate the distance between 2 points
 */
export const haversine_distance = (elem1: any, elem2: any) => {

    let R = 6371.0710; // Radius of the Earth in Kilometers
    let rlat1 = elem1.lat * (Math.PI/180); // Convert degrees to radians
    let rlat2 = elem2.lat * (Math.PI/180); // Convert degrees to radians
    let difflat = rlat2-rlat1; // Radian difference (latitudes)
    let difflon = (elem2.lng - elem1.lng) * (Math.PI/180); // Radian difference (longitudes)

    let d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    return d;
}; 