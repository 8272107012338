<div class="language-menu">
    <language-selector></language-selector>
</div>

<div class="logo-container">
    <div class="logo">
        <account-logo></account-logo>
    </div>
</div>

<div class="main-container">

    <div class="input-container">
        <div class="flex text-center justify-center mb-4">
            {{ 'register_confirm_title' | translate}}
        </div>
        <div class="flex">
            <mat-card class="middle-card">
                <mat-card-content>
                    <div class="card-content">
                        <div class="card-left-side">
                            <mat-icon class="card-left-icon">check_circle</mat-icon>
                        </div>
                        <div class="card-right-side">
                            {{ 'register_confirm_email_1' | translate}}
                            <span class="tap-link"> <strong> {{ getData().email }} </strong></span>
                            <span class="tap-link"> {{ 'register_confirm_email_2' | translate}} </span>
                            {{ 'register_confirm_email_3' | translate}}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="flex w-full mt-12 mb-6">
            <mat-divider class="flex w-full"></mat-divider>
        </div>

        <div class="flex">
            <fs-button [url]="'register-activate'" [text]="'register_confirm_manual_button' | translate"
                       [style]="'full-width-large-height'" [type]="'raised'" [leadingIcon]="'input'"></fs-button>
        </div>

        <div class="flex mt-4">
            <fs-button [url]="'login'" [text]="'register_confirm_login_button' | translate"
                       [style]="'full-width-large-height'" [type]="'raised'"
                       [leadingIcon]="'account_circle'"></fs-button>
        </div>

        <div class="flex mt-12 not-received-text">
            {{ 'register_confirm_did_not_receive' | translate }}
        </div>
        <div class="flex not-received-text mt-1">
            <fs-link [text]="'register_confirm_resend' | translate" [buttonBehavior]="true" (press)="resendEmailButton()"></fs-link>
        </div>
    </div>

</div>


