import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fs-link',
  templateUrl: './fs-link.component.html',
  styleUrls: ['./fs-link.component.scss'],
})
export class FsLinkComponent {
    @Input() url: string = "";
    @Input() text: string = "";
    @Input() buttonBehavior: boolean = false;

    @Output() press: EventEmitter<any> = new EventEmitter<any>();

    public onPress(e: any): void {
        this.press.emit(e);
    }
}
