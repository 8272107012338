import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter, Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

import { addressAnimations } from "libs/shared-models/src/lib/address/address-animations";
import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { DISTANCE_PINPOINT_THRESHOLD, mapViennaOptions, mapViennaOptionsCenter } from "libs/shared-models/src/lib/utils/address-constants";
import { haversine_distance } from "libs/shared-models/src/lib/utils/address-functions";

@Component({
  selector: 'fs-map-lite',
  templateUrl: './fs-map-lite.component.html',
  styleUrls: ['./fs-map-lite.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: addressAnimations
})

export class FsMapLiteComponent implements OnInit, AfterViewInit {
    /*
        Interacting with Google Maps api:
    */
    @ViewChild('mapSearchField') searchField: ElementRef | undefined;
    private map: google.maps.Map | undefined; // <google-map> component
    private mapMarker: google.maps.Marker | undefined;  // Pinpoint on the map
    private mapCircle: google.maps.Circle | undefined;
    public defaultMapOptions: google.maps.MapOptions = mapViennaOptions; // Boundaries region (Vienna)

    @Input() set address(value: AddressResponse | null) {
        let unchangedAddress = this.getTempAddress().latitude === value?.latitude && this.getTempAddress().longitude === value?.longitude;

        if (value && !unchangedAddress) {
            this.setTempAddress(value);
            this.redrawMarkerCircle();
        }
    }

    public _radius: number = 0;
    @Input() set radius(value: number) {
        this._radius = value;        
        this.redrawCircle();        
    }

    @Input() width: string | number = 500;
    @Input() height: string | number = 500;
    @Input() disableDefaultUI: boolean = false;
    

    private temporaryAddress$: BehaviorSubject<AddressResponse> = new BehaviorSubject<AddressResponse>(new AddressResponse(true)); // Edit one
    
    /*
        External comms
    */
    

    public constructor(
    ) {
    }

    public ngOnInit() {
        if (this.disableDefaultUI) {
            this.defaultMapOptions.disableDefaultUI = true;
        }
    }

    public ngAfterViewInit() {
        
    }

    public onMapReady(value: google.maps.Map) {
        this.map = value;
        this.redrawMarkerCircle();        
    }

    private redrawMarkerCircle() {
        this.addMarker();
        this.addCircle();
    }


    private addMarker() {
        // Marker icon
        const image = {
            url: "./libs/shared-ui/assets/images/address-pin-google-map.png",
            // This marker is 20 pixels wide by 32 pixels high.
            size: new google.maps.Size(51, 67),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(25, 67),
        };

        // reset marker (delete any previous drawn one)
        this.mapMarker?.setMap(null);

        // Add marker
        this.mapMarker = new google.maps.Marker({
            map: this.map,
            draggable: false,
            animation: google.maps.Animation.DROP,
            icon: image,
            position: {
                lat: this.getTempAddress().getLatitude(),
                lng: this.getTempAddress().getLongitude()
            },
        });
    }

    private addCircle() {

        // reset circle (delete any previous drawn one)
        this.mapCircle?.setMap(null);

        this.mapCircle = new google.maps.Circle({
            map: this.map,
            strokeColor: "#5E9925",
            strokeOpacity: 0.9,
            strokeWeight: 2,
            fillColor: "#5E9925",
            fillOpacity: 0.30,
            center:{
                lat: this.getTempAddress().getLatitude(),
                lng: this.getTempAddress().getLongitude()
            },
            radius: this._radius,
          });
    }
    
    private redrawCircle() {
        // redraw the circle
        this.mapCircle?.setRadius(this._radius);
        this.map?.setZoom(5);
    }

    public getTempAddress$(): Observable<AddressResponse> {
        return this.temporaryAddress$.asObservable();
    }

    public getTempAddress(): AddressResponse {
        return this.temporaryAddress$.getValue();
    }

    private setTempAddress(a: AddressResponse): void {
        this.temporaryAddress$.next(Object.assign(new AddressResponse(), a)); 
    }

}
