import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CookieService} from "./cookie.service";
import { BaseLoadingService } from './base-loading';

/*
    Takes care of the user's preferred language (locale) settings
 */

@Injectable({
  providedIn: 'root'
})
export class LocaleService extends BaseLoadingService {

    public readonly SUPPORTED_LOCALES = [{
        iso2: 'en',
        name: 'English',
    }, {
        iso2: 'de',
        name: 'Deutsch'
    }, {
        iso2: 'fr',
        name: 'Français'
    }, {
        iso2: 'bs',
        name: 'Bosanski'
    }, {
        iso2: 'sr',
        name: 'Srpski'
    }, {
        iso2: 'tr',
        name: 'Türkçe'
    }, {
        iso2: 'ro',
        name: 'Română'
    }, {
        iso2: 'pl',
        name: 'Polski'
    }, {
        iso2: 'hu',
        name: 'Węgierski'
    }, {
        iso2: 'es',
        name: 'Español'
    }, {
        iso2: 'hr',
        name: 'Hrvatski'
    }];

    public readonly DEFAULT_LANGUAGE = "en";
    private language$: BehaviorSubject<Language>= new BehaviorSubject<Language>(new Language());

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        @Inject('env') private environment: any
    ) {
        super();
        this.initService();
    }

    public initService() {
        this.initTranslations();
    }

    private initTranslations(): void {
        // Try to take current language from cookie
        const cookieLang = this.cookieService.getCookie('language');
        if (!!cookieLang && this.validateLanguage(cookieLang)) {
            this.setLanguage(cookieLang); 
            return;
        }

        // Try to take the language from Browser
        const browserLang = this.browserLanguage();
        if (!!browserLang && this.validateLanguage(browserLang)) {
            this.setLanguage(browserLang);
            return;
        }

        // Default, take default language
        this.setLanguage(this.DEFAULT_LANGUAGE); 
    }

    public translate(key: string): string {
        // get it from the stored one
        if (this.getLanguage()?.content?.hasOwnProperty(key)) {
            let value = this.getLanguage().content[key] || key;
            if (value.length > 0) {
                return value;
            }
        }

        // default
        return key;
    }

    private validateLanguage(lang: string): boolean {
        let value = this.SUPPORTED_LOCALES.find((item) => item.iso2 === lang)
        return !!value;
    }

    public getLanguage$(): Observable<Language> {
        return this.language$.asObservable();
    }

    private getLanguage(): Language {
        return this.language$.getValue();
    }

    public getLanguageISO2(): string {
        return this.language$.getValue().iso2;
    }

    public setLanguageISO2(lang: string) {
        this.setLanguage(lang);
    }

    private browserLanguage(): string {
        return navigator.language.split("-")[0].toLowerCase();
    }

    private setLanguage(lang: string) {
        if (!lang || this.getLanguageISO2() === lang)
            return; // skip

        this.fetchLanguageFile(lang).subscribe((content: TranslationContent) => {
            let newLanguage: Language = new Language();
            newLanguage.iso2 = lang;
            let nameIndex = this.SUPPORTED_LOCALES.findIndex((item) => item.iso2 === lang);
            newLanguage.name = this.SUPPORTED_LOCALES[nameIndex].name;
            newLanguage.content = content;
            this.cookieService.setCookie("language", lang);
            this.language$.next(newLanguage);
            this.setFinished();
        });
    }

    private fetchLanguageFile(lang: string): Observable<TranslationContent> {
        return this.http.get<TranslationContent>("./apps/" + this.environment.BUILD_PRODUCT + "/src/locale/" + lang + ".json");
    }
}

export interface TranslationContent {
    [key: string]: string;
}

export class Language {
    public iso2: string = "";
    public name: string = "";
    public content: any = null;
}
