import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fs-slider',
  templateUrl: './fs-slider.component.html',
  styleUrls: ['./fs-slider.component.scss'],
})
export class FsSliderComponent {

    @Input() maxValue: number = 0;
    @Input() minValue: number = 0;
    @Input() step: number = 0;

    @Output() onValueChange: EventEmitter<number> = new EventEmitter<number>();

    private _value: any;
    public set value(v: any) {
      this._value = v;
      this.onValueChange.emit(v);
    }
    public get value(): any {
      return this._value;
    }

    @Input() set inputValue(val: any) {
      if (val !== this.value) {
        this.value = val;
      }
    }

    formatLabel(value: number): string {
      return value + '';
    }
}
