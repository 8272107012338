import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fs-checkbox',
  templateUrl: './fs-checkbox.component.html',
  styleUrls: ['./fs-checkbox.component.scss'],
})
export class FsCheckboxComponent {
    @Input() checked: boolean = false;
    @Input() disabled: boolean = false;
    @Input() text: string = "";
    @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>()

    public onChange(event: any): void {
        this.valueChanged.emit(event);
    }
}
