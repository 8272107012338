import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {PageTitleService} from "libs/shared-services/src/lib/page-title.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {RegisterUserRequest} from "../../models/register-user-request";
import {BehaviorSubject, Subject, throttleTime} from "rxjs";
import {environment} from "../../../environments/environment";
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import { ExtraUserRegisterInvitation } from '../../models/extra-user-invitation-request';

/*
    Component (page) used to activate a new user based on the invitation (for eg: Kitchen user added from Restaurant)
    We exchange the email hash to get email / restaurant for display. And we ask to add a password / phone to this new account. 
*/

@UntilDestroy()
@Component({
  selector: 'extra-user-invitation-activate',
  templateUrl: './extra-user-invitation-activate.component.html',
  styleUrls: ['./extra-user-invitation-activate.component.scss'],
})
export class ExtraUserInvitationActivateComponent implements OnInit {

    private pageTitle = "page_title_register_confirm";

    public data$: BehaviorSubject<ExtraUserRegisterInvitation> = new BehaviorSubject<ExtraUserRegisterInvitation>(new ExtraUserRegisterInvitation());

    public trailingIcon: string = '';
    public passTrailingIconFade: boolean = true;
    public passInputType: string = 'password';


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private localeService: LocaleService,
        private pageTitleService: PageTitleService,
        private apiService: ApiBaseService,
        private toasterService: ToasterService
    ) {    
        this.localeService.getLanguage$().pipe(untilDestroyed(this)).subscribe(() => {
            this.pageTitleService.setTitle(this.localeService.translate(this.pageTitle));
        })
    }

    public ngOnInit() {
        this.route.paramMap.subscribe(params => {
            const exchangeCode = params.get('code');
            if (exchangeCode) {
                const data = this.getData();
                data.id = exchangeCode;
                this.setData(data);

                this.getInvitationData();
            }
        });
    }

    private getData(): ExtraUserRegisterInvitation {
        return this.data$.getValue();
    }

    private setData(data: ExtraUserRegisterInvitation) {
        this.data$.next(data);
    }

    private getInvitationData() {
        const url = environment.API_GET_KITCHEN_INVITATION.replace("{{id}}", this.getData().id);

        this.apiService.get(url).subscribe({
            next: (res) => {
                const resData = Object.assign(new ExtraUserRegisterInvitation(), res);

                const data = this.getData();
                data.email = resData.email;
                data.id = resData.id;
                data.restaurantName = resData.restaurantName;
                this.setData(data);
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    public phoneUpdate(value: string) {
        const data = this.getData();
        data.phoneNumber = value;
        this.setData(data);
    }

    public nameUpdate(value: string) {
        const data = this.getData();
        data.name = value;
        this.setData(data);
    }

    public passwordUpdate(value: string) {
        const data = this.getData();
        data.password = value;
        this.setData(data);
        
        if (!!value && value.length > 0) {
            this.trailingIcon = 'remove_red_eye';
        } else {
            this.trailingIcon = '';
        }
    }

    public onPasswordIconPress() {
        this.passTrailingIconFade = !this.passTrailingIconFade;
        this.passInputType = this.passInputType === 'password' ? 'text' : 'password';
    }

    
    public pressSubmit() {
        // Send API call        
        this.apiService.post(environment.API_POST_KITCHEN_INVITATION_CONFIRM, this.getData()).subscribe({
            next: (res: any) => {            
                this.toasterService.showSuccess("", this.localeService.translate("invitation_user_confirmed_successfully"));
                setTimeout(() => {
                    this.router.navigateByUrl("login");
                }, 1500)
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    public pressIgnore() {
        
    }
}
