import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fs-loading-spinner',
  templateUrl: './fs-loading-spinner.component.html',
  styleUrls: ['./fs-loading-spinner.component.scss'],
})
export class FsLoadingSpinnerComponent {

  @Input() public variant: string = "default_green";

  public LOADING_STYLE: typeof LOADING_STYLE = LOADING_STYLE;
}

export enum LOADING_STYLE {
  DEFAULT = "default_green",
  CIRCLE_GRAY = "circle_gray"
}
