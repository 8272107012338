<mat-form-field [class]="style" [appearance]="appearanceStyle" [subscriptSizing]="subscriptSizing" [floatLabel]="floatLabel">
    <mat-label>{{ label }}</mat-label>
    <input #inputRef matInput type="{{ type }}" [(ngModel)]="inputValue" (keydown.enter)="onEnterPress()" disabled="{{disabled}}" (keyup)="keyUp($event)">

    <!-- Prefix icon -->
    <button *ngIf="!!prefixIcon" matPrefix mat-icon-button class="trailing-button" (click)="onPrefixIconPress()" [ngClass]="{'fade': prefixIconFade}">
        <mat-icon>{{prefixIcon}}</mat-icon>
    </button>

    <!-- Suffix icon -->
    <button *ngIf="keepTrailingIconSpace" matSuffix mat-icon-button class="trailing-button keep-space {{!!trailingIcon ? 'active-icon' : ''}}" (click)="onTrailingIconPress()" [ngClass]="{'fade': trailingIconFade}">
        <mat-icon>{{trailingIcon}}</mat-icon>
    </button>
    <button *ngIf="!!trailingIcon && !keepTrailingIconSpace" matSuffix mat-icon-button class="trailing-button" (click)="onTrailingIconPress()" [ngClass]="{'fade': trailingIconFade}">
        <mat-icon>{{trailingIcon}}</mat-icon>
    </button>
    
</mat-form-field>



