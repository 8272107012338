import { Component, OnInit } from '@angular/core';
import { SharedServicesInit } from 'libs/shared-services/src/lib/shared-services-init';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'foodis-account-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  //TODO Cata: reduce the favicon.ico size

  public baseServicesLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isStaging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private sharedServicesInit: SharedServicesInit,
  ) {    
  }

  public ngOnInit() {

    // wait for base services to load first
    this.sharedServicesInit.allServicesLoaded$().subscribe((value) => {
      if (value) {
        this.baseServicesLoaded$.next(true);  
      }
    });   

    this.isStaging$.next(environment.DEBUG_STAGING_BADGE);
  }
}
