import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fs-radio-button',
  templateUrl: './fs-radio-button.component.html',
  styleUrls: ['./fs-radio-button.component.scss'],
})
export class FsRadioButtonComponent {
    @Input() options: IFsRadioButton[] = [];
    @Input() areaLabel: string = "";
    @Input() selectedValue: any = "";
    @Input() isVertical: boolean = false;

    @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>()

    public onChange(event: any): void {
        this.valueChanged.emit(event);
    }
}

export interface IFsRadioButton {
  id?: string;
  value?: any;
  text?: string;
}