<div class="language-menu">
    <language-selector></language-selector>
</div>

<div class="logo-container" [ngClass]="{'is-restaurant': isRestaurant()}">
    <div class="logo">
        <account-logo></account-logo>
    </div>
</div>

<div class="main-container">
    <div class="flex mb-4">
        <p>{{ 'register_description' | translate}}</p>
    </div>

    <div class="input-container">
        <div class="flex">
            <fs-input [label]="'register_name' | translate"
                      [style]="'full-width'" (enterPress)="submitRegisterClick()"
                      (valueUpdate)="nameUpdate($event)"></fs-input>
        </div>
        <div class="flex">
            <fs-input [label]="'register_email' | translate"
                      [style]="'full-width'" (enterPress)="submitRegisterClick()"
                      (valueUpdate)="emailUpdate($event)"></fs-input>
        </div>

        <!-- Extra fields for Restaurant registration -->
        <ng-container *ngIf="isRestaurant()">
            <div class="flex">
                <fs-input [label]="'register_business_company' | translate"
                          [style]="'full-width'" (enterPress)="submitRegisterClick()"
                          (valueUpdate)="companyUpdate($event)"></fs-input>
            </div>
            <div class="flex">
                <fs-input [label]="'register_business_phone' | translate"
                          [style]="'full-width'" (enterPress)="submitRegisterClick()"
                          (valueUpdate)="phoneUpdate($event)"></fs-input>
            </div>
        </ng-container>


        <div class="flex">
            <fs-input [label]="'register_password' | translate"
                      [style]="'full-width'" [type]="passInputType"
                      (valueUpdate)="passwordUpdate($event)"
                      [trailingIcon]="trailingIcon"
                      [trailingIconFade]="passTrailingIconFade"
                      (trailingIconPress)="onPasswordIconPress()"></fs-input>
        </div>


        <div class="flex remember-me">
            <fs-checkbox [text]="'register_accept_terms' | translate"
                         (valueChanged)="onCheckboxPress($event)"></fs-checkbox>
        </div>

        <div class="flex mt-7">
            <fs-button [text]="'register_button' | translate" [style]="'full-width-large-height'"
                       [type]="'raised-primary'" (onPress)="submitRegisterClick()"></fs-button>
        </div>

        <!-- Restaurant specific -->
        <ng-container *ngIf="isRestaurant(); else regularRegister">
            <div class="flex mt-12">
                <p>{{ 'register_regular_title' | translate }}</p>
            </div>
            <div class="flex mt-3">
                <fs-button [text]="'register_regular_button' | translate" [style]="'full-width-large-height'"
                           [type]="'raised'" [leadingIcon]="'person_add'"
                           [url]="'register'"></fs-button>
            </div>
        </ng-container>

        <!-- Regular user -->
        <ng-template #regularRegister>
            <div class="flex mt-12">
                <p>{{ 'register_business_title' | translate }}</p>
            </div>
            <div class="flex mt-3">
                <fs-button [text]="'register_business_button' | translate" [style]="'full-width-large-height'"
                           [type]="'raised'" [leadingIcon]="'store_mall_directory'"
                           [url]="'register-business'"></fs-button>
            </div>
        </ng-template>


    </div>
</div>


