<ng-container *ngIf="options.length > 0">
    <mat-radio-group [ariaLabel]="areaLabel" (change)="onChange($event)" [value]="selectedValue" 
        [ngClass]="{'vertical' : isVertical}">
        <ng-container *ngFor="let option of options; index as i ">
            <mat-radio-button class="radio-button" id='{{option?.id || ""}}' value="{{ option.value }}">
                <div class="radio-button-text">
                    {{ option.text }}
                </div>
            </mat-radio-button>
        </ng-container>
      </mat-radio-group>
</ng-container>
