import {inject, Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn} from '@angular/router';
import { AuthService } from './auth.service';
import { BaseLoadingService } from './base-loading';

@Injectable()
export class AuthGuardService  extends BaseLoadingService {
    constructor(private router: Router, private authService: AuthService) {
        super();
        this.setFinished();
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let isLoggedIn = this.authService.isLoggedIn();
        if (!isLoggedIn) {
            this.authService.goToLogin();
        }
        return isLoggedIn;
    }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    return inject(AuthGuardService).canActivate(next, state);
}