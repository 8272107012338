import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fs-menu-advanced',
  templateUrl: './fs-menu-advanced.component.html',
  styleUrls: ['./fs-menu-advanced.component.scss'],
})
export class FsMenuAdvancedComponent {
    @Input() leadingIcon: string = "";

    @Input() itemsList: any[] = [];
    @Input() selectedItem: any = null;
    @Input() itemIdAttribute: string = "id"; // default, if not specified
    @Input() itemValueAttribute: string = "name"; // default, if not specified
    @Input() disabled: boolean = false;

    @Input() preselectPlaceholder: string = "";
    @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

    public onItemSelect(event: Event) {
        let selected = this.itemsList.find((item) => item[this.itemIdAttribute] === (event.currentTarget as HTMLElement).id);
        if (!!selected) {
            this.preselectPlaceholder = "";
            this.selectedItem = selected;
            this.onSelect.emit(this.selectedItem);
        }
    }
}
