<!-- Title bar -->
<div class="generic-modal-top-bar">
    <!-- Left button container -->
    <div class="generic-modal-top-left-action"></div>

    <!-- Title -->
    <div class="generic-modal-top-title">
        {{ data.title }}
    </div>

    <!-- Right button container -->
    <div class="generic-modal-top-right-action" >

    </div>
</div>

<!-- Body -->
<div class="generic-modal-mid-content">
    {{ data.midContent }}
</div>

<!-- Bottom buttons -->
<div class="flex items-center justify-center p-5 w-full">

    <div class="button-container">
        <!-- Yes proeminent -->
        <fs-button [text]="data.leftButtonContent" [style]="'full-width-large-height'"
                   [type]="(data.preselectedButton === 'left') ? 'raised-primary' : 'raised'" (onPress)="onPressYes()"></fs-button>
    </div>

    <ng-container *ngIf="!data.oneButtonOnly">
        <div class="button-container">
            <!-- No -->
            <fs-button [text]="data.rightButtonContent" [style]="'full-width-large-height'"
                       [type]="(data.preselectedButton === 'right') ? 'raised-primary' : 'raised'" (onPress)="onPressNo()"></fs-button>
        </div>
    </ng-container>
</div>
