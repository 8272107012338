import {Component, OnInit} from '@angular/core';
import {Subject, throttleTime} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {PageTitleService} from "libs/shared-services/src/lib/page-title.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {environment} from "../../../environments/environment";
import {FinishResetPasswordRequest} from "../../models/reset-password-finish-request";

@UntilDestroy()
@Component({
  selector: 'forgot-password-activate',
  templateUrl: './forgot-password-activate.component.html',
  styleUrls: ['./forgot-password-activate.component.scss'],
})
export class ForgotPasswordActivateComponent implements OnInit{
    private pageTitle = "page_title_forgot_password_active";

    private requestData: FinishResetPasswordRequest = new FinishResetPasswordRequest();

    private submitClicks = new Subject();

    public trailingIcon: string = '';
    public passTrailingIconFade: boolean = true;
    public passInputType: string = 'password';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private localeService: LocaleService,
        private pageTitleService: PageTitleService
    ) {
    }

    public ngOnInit() {
        this.route.paramMap.subscribe(params => {
            let code = params.get('code');
            if (!!code) {
                this.requestData.resetKey = code;
            }
        });

        this.submitClicks.pipe(
            throttleTime(1500)
        ).pipe(untilDestroyed(this)).subscribe(e => {
            if (!this.requestData.resetKey) {
                this.toasterService.showInfo("", this.localeService.translate("forgot_password_activate_missing_code"));
                return;
            }
            if (!this.requestData.newPassword) {
                this.toasterService.showInfo("", this.localeService.translate("forgot_password_activate_missing_password"));
                return;
            }
            this.submitCodeToApi();
        });
        this.localeService.getLanguage$().pipe(untilDestroyed(this)).subscribe(() => {
            this.pageTitleService.setTitle(this.localeService.translate(this.pageTitle));
        })
    }

    private submitCodeToApi() {
        this.apiService.post(environment.API_FORGOT_PASSWORD_FINISH_URL, this.requestData).subscribe({
            next: () => {
                this.toasterService.showSuccess(
                    this.localeService.translate("forgot_password_success_title"),
                    this.localeService.translate("forgot_password_success_description"));
                this.router.navigateByUrl("login");
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        })
    }

    public codeUpdate(value: string) {
        this.requestData.resetKey = value;
    }

    public passwordUpdate(value: string) {
        this.requestData.newPassword = value;
        if (!!value && value.length > 0) {
            this.trailingIcon = 'remove_red_eye';
        } else {
            this.trailingIcon = '';
        }
    }

    public getResetKey(): string {
        return this.requestData.resetKey;
    }

    public submitClick() {
        this.submitClicks.next(true);
    }

    public onPasswordIconPress() {
        this.passTrailingIconFade = !this.passTrailingIconFade;
        this.passInputType = this.passInputType === 'password' ? 'text' : 'password';
    }
}
