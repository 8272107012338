import { Injectable } from '@angular/core';
import { extend } from 'dayjs';
import { BaseLoadingService } from './base-loading';

@Injectable({
  providedIn: 'root'
})
export class CookieService extends BaseLoadingService {

    constructor() { 
        super();        
        this.setFinished();
    }

    public getCookie(name: string, productSpecific: boolean = true, userPersonalized: boolean = true): string | null {
        let cookie = null;
        const product = document.cookie.match(new RegExp("product" + "=([^;]+)"));
        const userUID = document.cookie.match(new RegExp("user-uid" + "=([^;]+)"));

        // Product + User personalized cookie
        if (productSpecific && !!product) {
            if (userPersonalized && !!userUID) {
                cookie = document.cookie.match(new RegExp(product[1] + "-" + name + "-" + userUID[1] + "=([^;]+)"));
            } else {
                cookie = document.cookie.match(new RegExp(product[1] + "-" + name + "=([^;]+)"));
            }
        }

        // User personalized cookie
        if (userPersonalized && !!userUID) {
            cookie = document.cookie.match(new RegExp(name + "-" + userUID[1] + "=([^;]+)"));
        }
        if (!!cookie) {
            return "" + cookie[1];
        }

        // Regular cookie without product or user particularity
        cookie = document.cookie.match(new RegExp(`(?:^|;\\s*)${name}=([^;]+)`))
        if (!!cookie) {
            return "" + cookie[1];
        }

        // default
        return "";
    }

    public setCookie(name: string, value: string, product: string = "", userSpecific: boolean = false, expireDate?: Date, crossDomain: boolean = true, httpSecure: boolean = true, sameSite: boolean = true): void {
        if (!name) {
            console.error('[cookie] - No name provided. Cookie was not created.');
            return;
        }

        let cookieName = "";

        //TODO CATA: Check if the product is part of the interface / agreed types

        if (userSpecific) {
            let userUID = document.cookie.match(new RegExp("user-uid" + "=([^;]+)"));
            if (!!userUID) {
                if (!!product) {
                    cookieName = product + "-" + name + "-" + userUID[1];
                } else {
                    cookieName = name + "-" + userUID[1];
                }
            } else {
                console.warn('[cookie] - ' + name + '- User UID not available, will create default cookie');
            }
        } else {
            if (!!product) {
                cookieName = product + "-" + name;
            }
        }

        if (!cookieName) {
            cookieName = name;
        }

        const expire = expireDate ? expireDate.toUTCString() : new Date(+new Date() + 7 * 24 * 60 * 60 * 1000).toUTCString(); // 7 days

        let cookie = cookieName + "=" + value + ";expires=" + expire + "; path=/";

        if (crossDomain) {
            cookie += ";domain=" + this.getDomain();
        }

        if (httpSecure && location.protocol === "https:") {
            cookie += "; Secure";
        }

        if (sameSite) {
            cookie += "; SameSite=lax";
        }

        document.cookie = cookie

        //TODO CATA: add debug (env variable) possibility
        console.log('[cookie] - ' + cookieName + ' set successfully' + ' on domain ' + this.getDomain());
    }

    public deleteCookie(name: string, crossDomain: boolean = true) {
        let cookie =  name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        if (crossDomain) {
            cookie += ";domain=" + this.getDomain();
        }
        document.cookie = cookie;
    }

    // Gets base domain. Eg: app.foodis.day goes to foodis.day.  And foodis.day gets to the same foodis.day. 
    private getDomain(): string {
        let base = window.location.hostname.split(".");

        // Checks if I am on "localhost" or any single word of domain
        if (base.length === 1) {
            return base[0]; // just return and don't add dot
        }
        
        // If it has more than 2 aprts, eliminate first element
        if (base.length > 2) {
            base = base.slice(1);
        }

        return "." + base.join(".");
    }
}
