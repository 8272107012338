import {Component, OnInit} from '@angular/core';
import {Subject, throttleTime} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {PageTitleService} from "libs/shared-services/src/lib/page-title.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {environment} from "../../../environments/environment";
import {ResetPasswordInitRequest} from "../../models/reset-password-init-request";

@UntilDestroy()
@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    private pageTitle = "page_title_forgot_password";

    private requestData: ResetPasswordInitRequest = new ResetPasswordInitRequest();

    private submitClicks = new Subject();

    public resetLinkSent: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private localeService: LocaleService,
        private pageTitleService: PageTitleService
    ) {
    }

    public ngOnInit() {

        this.submitClicks.pipe(
            throttleTime(1500)
        ).pipe(untilDestroyed(this)).subscribe(e => {
            if (!this.requestData.email) {
                this.toasterService.showInfo("", this.localeService.translate("forgot_password_missing_email"));
                return;
            }
            this.submitCodeToApi();
        });
        this.localeService.getLanguage$().pipe(untilDestroyed(this)).subscribe(() => {
            this.pageTitleService.setTitle(this.localeService.translate(this.pageTitle));
        })
    }

    private submitCodeToApi() {
        this.apiService.post(environment.API_FORGOT_PASSWORD_URL, this.requestData).subscribe({
            next: () => {
               this.resetLinkSent = true;
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        })
    }

    public emailUpdate(value: string) {
        this.requestData.email = value;
    }

    public getData(): ResetPasswordInitRequest {
        return this.requestData;
    }

    public submitCodeClick() {
        this.submitClicks.next(true);
    }

}
