import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapFormatConverterPipe } from './google-map-format';

@NgModule({
  imports: [CommonModule],
  exports: [
    GoogleMapFormatConverterPipe
  ],
  declarations: [
    GoogleMapFormatConverterPipe
  ]
})
export class SharedModelsModule {}
