import { Pipe, PipeTransform } from '@angular/core';
import {AddressResponse} from "libs/shared-models/src/lib/address-response";
import {mapViennaOptions, mapViennaOptionsZoom} from "libs/shared-models/src/lib/utils/address-constants";
@Pipe({
    name: 'google_map_format_converter',
    pure: true
})
export class GoogleMapFormatConverterPipe implements PipeTransform {
    transform(data: AddressResponse | null, radius?: any, disableDefaultUI?: boolean): any {

        if (!data) {
            return mapViennaOptions;
        }

        let zoom = mapViennaOptionsZoom;
        if (radius) {
            if (radius > 0 && radius <300) {
                zoom = 16;
            }
            if (radius >= 300 && radius <500) {
                zoom = 15.3;
            }
            if (radius >= 500 && radius <800) {
                zoom = 14.5;
            }
            if (radius >= 800 && radius <1000) {
                zoom = 14.0;
            }
            if (radius >= 1000 && radius <1400) {
                zoom = 13.7;
            }
            if (radius >= 1400 && radius <1800) {
                zoom = 13.3;
            }
            if (radius >= 1800 && radius <2100) {
                zoom = 13;
            }
            if (radius >= 2100 && radius <2400) {
                zoom = 12.8;
            }
            if (radius >= 2400) {
                zoom = 12.6;
            }
        }

        return {
            center: {lat: data.getLatitude(), lng: data.getLongitude()},
            zoom: zoom,
            disableDefaultUI: disableDefaultUI
        };
    }
}