<div class="language-menu">
    <language-selector></language-selector>
</div>

<div class="logo-container">
    <div class="logo">
        <account-logo></account-logo>
    </div>
</div>

<div class="main-container">

    <div class="input-container">

        <!-- Input email screen -->

        <ng-container *ngIf="!resetLinkSent; else linkSent">
            <div class="flex mb-4 text-center justify-center">
                {{ 'forgot_password_description' | translate}}
            </div>

            <div class="flex mt-8 mb-4 ">
                {{ 'forgot_password_explanation' | translate}}
            </div>

            <div class="flex mt-3">
                <fs-input [label]="'forgot_password_email_label' | translate"
                          [style]="'full-width'" (enterPress)="submitCodeClick()"
                          (valueUpdate)="emailUpdate($event)"></fs-input>
            </div>

            <div class="flex mt-3">
                <fs-button [text]="'forgot_password_submit_button' | translate" [style]="'full-width-large-height'"
                           [type]="'raised-primary'" (onPress)="submitCodeClick()"></fs-button>
            </div>

            <div class="flex w-full mt-20 mb-3">
                <mat-divider class="flex w-full"></mat-divider>
            </div>

            <div class="flex mt-4">
                <fs-button [url]="'login'" [text]="'register_confirm_login_button' | translate"
                           [style]="'full-width-large-height'" [type]="'raised'"
                           [leadingIcon]="'account_circle'"></fs-button>
            </div>
        </ng-container>


        <!-- Reset Link was sent successfully on the email -->

        <ng-template #linkSent>
            <div class="flex text-center justify-center mb-4">
                {{ 'forgot_password_sent_title' | translate}}
            </div>
            <div class="flex">
                <mat-card class="middle-card">
                    <mat-card-content>
                        <div class="card-content">
                            <div class="card-left-side">
                                <mat-icon class="card-left-icon">check_circle</mat-icon>
                            </div>
                            <div class="card-right-side">
                                {{ 'forgot_password_email_1' | translate}}
                                <span class="tap-link"> <strong> {{ getData().email }} </strong></span>
                                <span class="tap-link"> {{ 'forgot_password_email_2' | translate}} </span>
                                {{ 'forgot_password_email_3' | translate}}
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="flex w-full mt-12 mb-6">
                <mat-divider class="flex w-full"></mat-divider>
            </div>

            <div class="flex">
                <fs-button [url]="'forgot-password-activate'" [text]="'register_confirm_manual_button' | translate"
                           [style]="'full-width-large-height'" [type]="'raised'" [leadingIcon]="'input'"></fs-button>
            </div>

            <div class="flex mt-4">
                <fs-button [url]="'login'" [text]="'register_confirm_login_button' | translate"
                           [style]="'full-width-large-height'" [type]="'raised'"
                           [leadingIcon]="'account_circle'"></fs-button>
            </div>
        </ng-template>
    </div>
</div>
