import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'fs-menu-simple',
  templateUrl: './fs-menu-simple.component.html',
  styleUrls: ['./fs-menu-simple.component.scss'],
})
export class FsMenuSimpleComponent {
    @Input() leadingIcon: string = "";
    @Input() itemsList: string[] = [];
    @Input() itemsListShort: string[] = []; // If you want to also keep a minified version of the list  (eg: "EN" instead of "English")
    @Input() selectedItem: number = 0;
    @Input() showButtonShort: boolean = false;
    @Input() smallHeight: boolean = false;
    @Input() set itemValue(value: string) {
      if (this.selectedItem === this.getSelectedPosition(value)) {
        return; // ignore in order to not emit again
      }
      this.selectedItem = this.getSelectedPosition(value);
      this.onSelect.emit(this.selectedItem);
    }


    @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

    public onItemSelect(event: Event) {
        this.selectedItem = +(event.currentTarget as HTMLElement).id;
        this.onSelect.emit(this.selectedItem);
    }


    private getSelectedPosition(value: string): number {
      let index = this.itemsList.findIndex((i) => i === value);
      return !!index ? index : 0;
    }
}
