import { Injectable } from '@angular/core';
import {CookieService} from "./cookie.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Router} from "@angular/router";
import { BaseLoadingService } from './base-loading';
import { Constants } from "libs/shared-models/src/lib/utils/constants";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseLoadingService {

  private $loginState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
      private cookieService: CookieService,
      private router: Router) {
        super();

      if (this.isLoggedIn()) {
          this.$loginState.next(true);
      }      
      this.setFinished();
  }

  public $getLoginState(): Observable<boolean> {
      return this.$loginState.asObservable()
  }

  public isLoggedIn(): boolean {
      let token = this.cookieService.getCookie("token");
      return !!token;
  }

  public doLogout(redirectToLogin: boolean = true) {
      this.cookieService.deleteCookie('token');
      this.$loginState.next(false);

      if (redirectToLogin) {
          this.router.navigateByUrl("login");
      }
  }

  public getToken(): string {
      let token = this.cookieService.getCookie("token");
      return token || '';
  }

  public goToLogin() {
      // window.location.href = this.loginURL; //TODO: check which option would be better
      this.router.navigateByUrl("login");
  }

  public goToLoginWithParams(redirectFlow = "", redirectUrl = "") {

    const qParams: any = {};
    if (redirectFlow) {
        qParams[Constants.QUERY_PARAM_REDIRECT_FLOW] = redirectFlow;
    }
    if (redirectUrl) {
        qParams[Constants.QUERY_PARAM_REDIRECT_URL] = encodeURIComponent(redirectUrl); // encode it
    }

    this.router.navigate(
        ['login'], 
        { 
            queryParams: qParams
        }
    );
  }
}
