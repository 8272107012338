import { Component, OnInit } from '@angular/core';
import { CookieService } from 'libs/shared-services/src/lib/cookie.service';
import { ApiBaseService } from "libs/shared-services/src/lib/api-base.service";
import { LocaleService } from "libs/shared-services/src/lib/locale.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToasterService } from "libs/shared-services/src/lib/toaster.service";
import { Subject, throttleTime } from "rxjs";
import { AuthService } from "libs/shared-services/src/lib/auth.service";
import { environment } from "../../../environments/environment";
import { PageTitleService } from "libs/shared-services/src/lib/page-title.service";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoginUserRequest } from "../../models/login-user-request";
import { CurrentProductService } from "libs/shared-services/src/lib/current-product.service";
import { Constants } from "libs/shared-models/src/lib/utils/constants";
import { LocalStorageService } from 'libs/shared-services/src/lib/local-storage.service';

@UntilDestroy()
@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

    private pageTitle = "page_title_login";
    private loginDataReq: LoginUserRequest = new LoginUserRequest();

    private submitClicks = new Subject();

    public trailingIcon: string = '';
    public passTrailingIconFade: boolean = true;
    public passInputType: string = 'password';

    public registerURL: string = 'register';

    public constructor(
        private cookieService: CookieService,
        private localStorageService: LocalStorageService,
        private authService: AuthService,
        private localeService: LocaleService,
        private apiService: ApiBaseService,
        private router: Router,
        private route: ActivatedRoute,
        private toasterService: ToasterService,
        private pageTitleService: PageTitleService,
        private currentProductService: CurrentProductService
    ) {
    }

    public ngOnInit() {
        
        // Prevent multiple submissions
        this.submitClicks.pipe(
            throttleTime(1500)
        ).pipe(untilDestroyed(this)).subscribe(e => {
            if (!this.loginDataReq.email) {
                this.toasterService.showInfo("", this.localeService.translate("login_error_missing_email"));
                return;
            }

            if (!this.loginDataReq.password) {
                this.toasterService.showInfo("", this.localeService.translate("login_error_missing_password"));
                return;
            }
            this.submitLoginData();
        });

        // Automatically go to the product if it's logged in already 
        if (this.authService.isLoggedIn()) {
            this.navigateToProduct();
        }

        // Language sync
        this.localeService.getLanguage$().pipe(untilDestroyed(this)).subscribe(() => {
            this.pageTitleService.setTitle(this.localeService.translate(this.pageTitle));
        })

        // Register URL
        if (this.currentProductService.isRestaurant()) {
            this.registerURL = "register-business";
        }

        // Store redirect flow or url on cookie
        this.route.queryParamMap.subscribe(params => {            
            const redirectFlow = params.get(Constants.QUERY_PARAM_REDIRECT_FLOW);
            const redirectUrl = params.get(Constants.QUERY_PARAM_REDIRECT_URL);
            if (redirectFlow) {
                this.localStorageService.saveData('fs-temp-account-redirect-flow', redirectFlow, false);
            }
            if (redirectUrl) {
                this.localStorageService.saveData('fs-temp-account-redirect-url', redirectUrl, false);
            }
        });
    }

    public emailUpdate(value: string) {
        this.loginDataReq.email = value;
    }

    public passwordUpdate(value: string) {
        this.loginDataReq.password = value;
        if (!!value && value.length > 0) {
            this.trailingIcon = 'remove_red_eye';
        } else {
            this.trailingIcon = '';
        }
    }

    public submitClick() {
        this.submitClicks.next(true);
    }

    public submitLoginData(): void {
        this.apiService.post(environment.API_LOGIN_URL, this.loginDataReq).subscribe((res) => {
            let token = res?.token;
            if (!!token) {
                this.cookieService.setCookie('token', token);
                this.navigateToProduct();
            }
        }, (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        })
    }

    private navigateToProduct() {

        const redirectFlow = this.localStorageService.getData("fs-temp-account-redirect-flow", false);
        const redirectUrl = this.localStorageService.getData("fs-temp-account-redirect-url", false);

        if (redirectFlow || redirectUrl) {
            if (redirectUrl) {
                this.localStorageService.removeData("fs-temp-account-redirect-url");
                window.location.href = decodeURIComponent(redirectUrl);
                return;
            } 
            if (redirectFlow) {                
                // TODO in the future / if we need any custom parsing of the flow
                this.localStorageService.removeData("fs-temp-account-redirect-flow");
                window.location.href = this.currentProductService.getProductURL() + "/" + redirectFlow;
                return;
            }
        } else {
            // Go to default product
            // Comment this line when you need to test with localhost development - otherwise you'll always get redirected to deployed version
            window.location.href = this.currentProductService.getProductURL();
        }        
    }

    public onPasswordIconPress() {
        this.passTrailingIconFade = !this.passTrailingIconFade;
        this.passInputType = this.passInputType === 'password' ? 'text' : 'password';
    }
}
