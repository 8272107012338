import {Component, Input} from '@angular/core';

@Component({
  selector: 'fs-debug-badge',
  templateUrl: './fs-debug-badge.component.html',
  styleUrls: ['./fs-debug-badge.component.scss'],
})
export class FsDebugBadgeComponent {
    @Input() text = "Staging";
}
