import {Component, OnInit} from '@angular/core';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {Subject, throttleTime} from "rxjs";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {environment} from "../../../environments/environment";
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {CookieService} from "libs/shared-services/src/lib/cookie.service";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {PageTitleService} from "libs/shared-services/src/lib/page-title.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {RegisterUserRequest} from "../../models/register-user-request";
import {CurrentProductService} from "libs/shared-services/src/lib/current-product.service";
import {Product} from "libs/shared-models/src/lib/product-type";

@UntilDestroy()
@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

    private pageTitle = "page_title_register";

    public registerData: RegisterUserRequest = new RegisterUserRequest();

    public trailingIcon: string = '';
    public passTrailingIconFade: boolean = true;
    public passInputType: string = 'password';
    public termsCheckboxAccepted: boolean = false;

    private submitClicks = new Subject();

    constructor(
        private toasterService: ToasterService,
        private localeService: LocaleService,
        private apiService: ApiBaseService,
        private cookieService: CookieService,
        private pageTitleService: PageTitleService,
        private currentProductService: CurrentProductService,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    public ngOnInit() {
        // prevent multiple submissions
        this.submitClicks.pipe(
            throttleTime(1500)
        ).pipe(untilDestroyed(this)).subscribe(e => {

            if (!this.registerData.name) {
                this.toasterService.showInfo("", this.localeService.translate("register_missing_name"));
                return;
            }

            if (!this.registerData.email) {
                this.toasterService.showInfo("", this.localeService.translate("register_missing_email"));
                return;
            }

            if (this.isRestaurant() && !this.registerData.companyName) {
                this.toasterService.showInfo("", this.localeService.translate("register_business_missing_company"));
                return;
            }
            if (this.isRestaurant() && !this.registerData.phoneNumber) {
                this.toasterService.showInfo("", this.localeService.translate("register_business_missing_phone"));
                return;
            }

            if (!this.registerData.password) {
                this.toasterService.showInfo("", this.localeService.translate("register_missing_password"));
                return;
            }

            if (!this.termsCheckboxAccepted) {
                this.toasterService.showInfo("", this.localeService.translate("register_terms_not_checked"));
                return;
            }

            this.submitVerificationData();
        });

        this.localeService.getLanguage$().pipe(untilDestroyed(this)).subscribe(() => {
            this.pageTitleService.setTitle(this.localeService.translate(this.pageTitle));
        })

        this.route.url.subscribe(params => {
            let currentRoute = params[0]?.path;
            if (!!currentRoute && currentRoute === "register-business") {
                this.currentProductService.setProduct(Product.RESTAURANT);
            } else {
                this.currentProductService.setProduct(Product.USER_APP);
            }
        })
    }

    public submitRegisterClick() {
        this.submitClicks.next(true);
    }

    public onCheckboxPress(event: MatCheckboxChange) {
       this.termsCheckboxAccepted = event.checked;
    }

    public onPasswordIconPress() {
        this.passTrailingIconFade = !this.passTrailingIconFade;
        this.passInputType = this.passInputType === 'password' ? 'text' : 'password';
    }

    public nameUpdate(value: string) {
        this.registerData.name = value;
    }

    public emailUpdate(value: string) {
        this.registerData.email = value;
    }

    public companyUpdate(value: string) {
        this.registerData.companyName = value;
    }

    public phoneUpdate(value: string) {
        this.registerData.phoneNumber = value;
    }


    public passwordUpdate(value: string) {
        this.registerData.password = value;
        if (!!value && value.length > 0) {
            this.trailingIcon = 'remove_red_eye';
        } else {
            this.trailingIcon = '';
        }
    }

    private submitVerificationData() {
        // API expects it uppercase all the time - and with underline instead of minus
        this.registerData.product = this.currentProductService.getProduct().toUpperCase().replace("-", "_");

        let requestBody = {
            name: this.registerData.name,
            email: this.registerData.email,
            password: this.registerData.password,
            product: this.registerData.product
        }

        if (this.isRestaurant()) {
            // extra append the other fields
            requestBody = {
                ...requestBody,
                ...{
                    companyName: this.registerData.companyName,
                    phoneNumber: this.registerData.phoneNumber
                }
            }
        }

        this.apiService.post(environment.API_REGISTER_URL, requestBody).subscribe((res) => {
            this.registerData.password = ""; // clean it
            const navigationExtras: NavigationExtras = {
                state: this.registerData
            };
            this.router.navigate(['register-confirm'], navigationExtras);
        }, (err) => {
            this.toasterService.showError("Error", err?.error?.message);
        })
    }

    public isRestaurant(): boolean {
        return this.currentProductService.isRestaurant();
    }
}