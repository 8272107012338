import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleService } from "./locale.service";
import { CookieService } from "./cookie.service";
import { ApiBaseService } from "./api-base.service";
import { HttpClientModule } from "@angular/common/http";
import {LocaleTranslatePipe} from "./locale-pipe";
import { ToastrModule } from 'ngx-toastr';
import {AuthService} from "./auth.service";
import {AuthGuardService} from "./auth-guard.service";
import {PageTitleService} from "./page-title.service";
import {SharedModelsModule} from "@web-foodis/shared-models";
import {TrackingService} from "./tracking.service";
import {FormatCentsToFloatPipe} from "./cents-to-float-pipe";
import {BytesToMegaBytesPipe} from './bytes-to-mb-pipe';
import { SharedServicesInit } from './shared-services-init';
import { FormatDistancePipe } from './format-distance';
import { TimezoneService } from './timezone.service';
import { TimezonePipe } from './timezone.pipe';

@NgModule({
    imports: [CommonModule, HttpClientModule,
        SharedModelsModule,
        // BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(), // ToastrModule added
    ],
    exports: [LocaleTranslatePipe, FormatCentsToFloatPipe, BytesToMegaBytesPipe, FormatDistancePipe, TimezonePipe, SharedModelsModule],
    declarations: [LocaleTranslatePipe, FormatCentsToFloatPipe, BytesToMegaBytesPipe, FormatDistancePipe, TimezonePipe],
    providers: [ApiBaseService, AuthService, AuthGuardService, CookieService, LocaleService, PageTitleService, TrackingService]
})
export class SharedServicesModule {

    private static isInitialized: boolean = false;

    public static forRoot(environment: any): ModuleWithProviders<SharedServicesModule> {
        if (this.isInitialized) {
            throw new Error('do not call forRoot multiple times');
        }
        this.isInitialized = true;
        return {
            ngModule: SharedServicesModule,
            providers: [
                ApiBaseService,
                {
                    provide: 'env',
                    useValue: environment
                },
                LocaleService,
                CookieService,
                AuthService,
                AuthGuardService,
                PageTitleService,
                TrackingService,
                TimezoneService,
                LocaleTranslatePipe,
                FormatCentsToFloatPipe,
                BytesToMegaBytesPipe,
                FormatDistancePipe,
                TimezonePipe,
                SharedServicesInit
            ]
        };
    }
    public constructor(
        private sharedServicesInit: SharedServicesInit,
        @Optional() @SkipSelf() parentModule?: SharedServicesModule,
    ) {
        if (!SharedServicesModule.isInitialized) {
            throw new Error('call forRoot first');
        }

        if (parentModule) {
            /*throw new Error(
                'SharedServicesModule is already loaded. Import it in the AppModule only');*/
            console.warn('SharedServicesModule is already loaded. Import it in the AppModule only');
        }
    }
}
