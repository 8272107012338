<button mat-button [matMenuTriggerFor]="menu">
    <div class="flex">
        <ng-container *ngIf="!!leadingIcon">
            <img [src]="leadingIcon" class="leading-icon" alt="language globe"/>
        </ng-container>
        <span class="main-item">
            <ng-container *ngIf="!showButtonShort; else showShortVariant">
                {{ itemsList[selectedItem] }}
            </ng-container>
            <ng-template #showShortVariant>
                {{ itemsListShort[selectedItem] }}
            </ng-template>
        </span>
        <span class="flex">
            <img src="./libs/shared-ui/assets/icons/arrow-down.svg" alt="dropdown arrow"/>
        </span>
    </div>
</button>
<mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of itemsList; index as i">
        <button mat-menu-item [id]="i" (click)="onItemSelect($event)" 
            [ngClass]="{ 
                    'selected-item' : selectedItem === i,
                    'small-height' : smallHeight
                }" >
                {{ item }}
        </button>
    </ng-container>
</mat-menu>
