import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format_distance'
})
export class FormatDistancePipe implements PipeTransform {
  transform(value: number): string {
    // Handle the special case of exactly zero
    if (value < 0.1) {
      return '<0.1 km';
    }

    // Round the number to one decimal place
    const rounded = Math.round(value * 10) / 10;

    // Convert number to a formatted string with one decimal place
    const formatted = rounded.toFixed(1);

    return `${formatted} km`;
  }
}