import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedUiModule } from '@web-foodis/shared-ui';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SharedServicesModule } from '@web-foodis/shared-services';
import { environment } from '../environments/environment';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterConfirmComponent } from './components/register-confirm/register-confirm.component';
import { RegisterActivateComponent } from './components/register-activate/register-activate.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordActivateComponent } from './components/forgot-password-activate/forgot-password-activate.component';
import { AccountLogoComponent } from './components/account-logo/account-logo.component';
import { ExtraUserInvitationActivateComponent } from './components/extra-user-invitation-activate/extra-user-invitation-activate.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    LanguageSelectorComponent,
    RegisterComponent,
    RegisterConfirmComponent,
    RegisterActivateComponent,
    ForgotPasswordComponent,
    ForgotPasswordActivateComponent,
    AccountLogoComponent,
    ExtraUserInvitationActivateComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    BrowserAnimationsModule,
    SharedServicesModule.forRoot(environment),
    SharedUiModule,
  ],
  providers: [SharedServicesModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
