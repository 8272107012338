import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {PageTitleService} from "libs/shared-services/src/lib/page-title.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {RegisterUserRequest} from "../../models/register-user-request";
import {Subject, throttleTime} from "rxjs";
import {environment} from "../../../environments/environment";
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";

@UntilDestroy()
@Component({
  selector: 'register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.scss'],
})
export class RegisterConfirmComponent implements OnInit {

    private pageTitle = "page_title_register_confirm";

    private registerData: RegisterUserRequest = new RegisterUserRequest();

    private resendClicks = new Subject();

    constructor(
        private router: Router,
        private localeService: LocaleService,
        private pageTitleService: PageTitleService,
        private apiService: ApiBaseService,
        private toasterService: ToasterService
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (!!navigation) {
            const state = navigation.extras.state as RegisterUserRequest;
            if (!!state) {
                this.registerData = state;
            }
        }
        this.localeService.getLanguage$().pipe(untilDestroyed(this)).subscribe(() => {
            this.pageTitleService.setTitle(this.localeService.translate(this.pageTitle));
        })
    }

    public ngOnInit() {
        this.resendClicks.pipe(
            throttleTime(1500)
        ).pipe(untilDestroyed(this)).subscribe(e => {
            this.resendEmail();
        });
    }

    public getData(): RegisterUserRequest {
        return this.registerData;
    }

    private resendEmail() {
        let url = environment.API_ACTIVATE_EMAIL_RESEND_URL + "?email=" + encodeURIComponent(this.registerData.email);

        this.apiService.get(url).subscribe({
            next: () => {
                this.toasterService.showSuccess(
                    this.localeService.translate("register_confirm_resend_success_title"),
                    this.localeService.translate("register_confirm_resend_success_description"));
            },
            error: (err) => {
                this.toasterService.showError("Error", err?.error?.message);
            }
        });
    }

    public resendEmailButton() {
        this.resendClicks.next(true);
    }
}
