<ng-container *ngIf="type === 'basic'">
    <button [disabled]="disabled" mat-button [ngClass]="style" (click)="onClick($event)" class="button-container">
        <ng-container *ngIf="!!leadingIcon">
            <mat-icon class="leading-icon" >{{ leadingIcon }}</mat-icon>
        </ng-container>
        <ng-container *ngIf="!!leadingIconUrl">
            <img src="{{ './libs/shared-ui/assets/icons/' + leadingIconUrl }}" alt="icon" class="leading-icon pr-1"/>
        </ng-container>
        <span class="text-normal">
            {{ text }}
        </span>
    </button>
</ng-container>

<ng-container *ngIf="type === 'raised'">
    <button [disabled]="disabled"  mat-raised-button [ngClass]="style" (click)="onClick($event)" class="button-container">
        <ng-container *ngIf="!!leadingIcon">
            <mat-icon class="leading-icon-raised">{{ leadingIcon }}</mat-icon>
        </ng-container>
        <ng-container *ngIf="!!leadingIconUrl">
            <img src="{{ './libs/shared-ui/assets/icons/' + leadingIconUrl }}" alt="icon" class="leading-icon  pr-1"/>
        </ng-container>
        <span class="text-normal">
            {{ text }}
        </span>
    </button>
</ng-container>

<ng-container *ngIf="type === 'raised-custom-icon-only'">
    <button [disabled]="disabled"  mat-raised-button [ngClass]="style" (click)="onClick($event)" class="button-container">
        <ng-container *ngIf="!!leadingIcon">
            <mat-icon [ngClass]="leadingIconStyle">{{ leadingIcon }}</mat-icon>
        </ng-container>
        <ng-container *ngIf="!!leadingIconUrl">
            <img src="{{ './libs/shared-ui/assets/icons/' + leadingIconUrl }}" alt="icon" class="leading-icon  pr-1"/>
        </ng-container>
        <span class="text-normal">
            {{ text }}
        </span>
    </button>
</ng-container>


<ng-container *ngIf="type === 'raised-primary'">
    <button [disabled]="disabled"  mat-raised-button color="primary" [ngClass]="style" (click)="onClick($event)" class="button-container">
        <ng-container *ngIf="!!leadingIcon">
            <mat-icon class="leading-icon-primary">{{ leadingIcon }}</mat-icon>
        </ng-container>
        <ng-container *ngIf="!!leadingIconUrl">
            <img src="{{ './libs/shared-ui/assets/icons/' + leadingIconUrl }}" alt="icon" class="leading-icon pr-1" />
        </ng-container>
        <span class="text-raised">
            {{ text }}
        </span>
    </button>
</ng-container>

<ng-container *ngIf="type === 'raised-primary-warn'">
    <button [disabled]="disabled"  mat-raised-button color="warn" [ngClass]="style" (click)="onClick($event)" class="button-container">
        <ng-container *ngIf="!!leadingIcon">
            <mat-icon class="leading-icon-primary">{{ leadingIcon }}</mat-icon>
        </ng-container>
        <ng-container *ngIf="!!leadingIconUrl">
            <img src="{{ './libs/shared-ui/assets/icons/' + leadingIconUrl }}" alt="icon" class="leading-icon pr-1" />
        </ng-container>
        <span class="text-raised">
            {{ text }}
        </span>
    </button>
</ng-container>


<ng-container *ngIf="type === 'stroked'">
    <button [disabled]="disabled"  mat-stroked-button [ngClass]="style" (click)="onClick($event)" class="button-container">
        <ng-container *ngIf="!!leadingIcon">
            <mat-icon class="leading-icon-raised">{{ leadingIcon }}</mat-icon>
        </ng-container>
        <ng-container *ngIf="!!leadingIconUrl">
            <img src="{{ './libs/shared-ui/assets/icons/' + leadingIconUrl }}" alt="icon" class="leading-icon  pr-1"/>
        </ng-container>
        <span class="text-normal">
            {{ text }}
        </span>
    </button>
</ng-container>