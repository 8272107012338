export const environment = {
    BUILD_PRODUCT: "account",
    API_URL_BASE: "https://api.staging.foodis.day/",
    API_LOGIN_URL: "v1/users/login",
    API_REGISTER_URL: "v1/users/register",
    API_ACTIVATE_EMAIL_URL: "v1/users/activate",
    API_ACTIVATE_EMAIL_RESEND_URL: "v1/users/resend-activation-email",
    API_FORGOT_PASSWORD_URL: "v1/users/password-reset/init",
    API_FORGOT_PASSWORD_FINISH_URL: "v1/users/password-reset/finish",
    API_GET_KITCHEN_INVITATION: "v1/public/invitations/kitchen/{{id}}",
    API_POST_KITCHEN_INVITATION_CONFIRM: "v1/users/register-invitation",
    PRODUCT_URLS: {
        LANDING_PAGE: "https://staging.foodis.day",
        USER_APP: "https://app.staging.foodis.day",
        RESTAURANT: "https://restaurant.staging.foodis.day",
        DRIVER: "https://driver.staging.foodis.day",
        ADMIN: "https://admin.staging.foodis.day"
    },
    DEBUG_STAGING_BADGE: true
};
