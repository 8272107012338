import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'timezone_utc_to_zone',
    pure: false //TODO Cata: for the future, find a better solution to update only call on change, not on every change detection cycle
})
export class TimezonePipe implements PipeTransform {
    constructor(
    ) {
    }

    /*
      Transform from UTC to Timezone
    */
    public transform(utcTime: string, timezoneOffset: string): string {
        if (!utcTime || !timezoneOffset) {
          return utcTime; // Return the original time if any input is invalid
        }
    
        const [utcHours, utcMinutes] = utcTime.split(':').map(Number);
        const sign = timezoneOffset[0];
        let [offsetHours, offsetMinutes] = timezoneOffset.slice(1).split(':').map(Number);

        if (this.isDaylightSavingTime()) {
          offsetHours += 1;
        }
    
        let totalMinutes = utcHours * 60 + utcMinutes;
    
        if (sign === '+') {
          totalMinutes += offsetHours * 60 + offsetMinutes;
        } else if (sign === '-') {
          totalMinutes -= offsetHours * 60 + offsetMinutes;
        }
    
        let resultHours = Math.floor(totalMinutes / 60) % 24;
        let resultMinutes = totalMinutes % 60;
    
        if (resultMinutes < 0) {
          resultMinutes += 60;
        }
    
        if (resultHours < 0) {
          resultHours += 24;
        }
    
        const formattedHours = String(resultHours).padStart(2, '0');
        const formattedMinutes = String(resultMinutes).padStart(2, '0');
    
        return `${formattedHours}:${formattedMinutes}`;
    }

    /*
      Transform from Timezone to UTC
    */
    public reverseTransform(localTime: string, timezoneOffset: string): string {
      if (!localTime || !timezoneOffset) {
        return localTime; // Return the original time if any input is invalid
      }
    
      const [localHours, localMinutes] = localTime.split(':').map(Number);
      const sign = timezoneOffset[0];
      let [offsetHours, offsetMinutes] = timezoneOffset.slice(1).split(':').map(Number);

      if (this.isDaylightSavingTime()) {
        offsetHours += 1;
      }  
    
      let totalMinutes = localHours * 60 + localMinutes;
    
      if (sign === '+') {
        totalMinutes -= offsetHours * 60 + offsetMinutes;
      } else if (sign === '-') {
        totalMinutes += offsetHours * 60 + offsetMinutes;
      }
    
      let resultHours = Math.floor(totalMinutes / 60) % 24;
      let resultMinutes = totalMinutes % 60;
    
      if (resultMinutes < 0) {
        resultMinutes += 60;
        resultHours -= 1;
      }
    
      if (resultHours < 0) {
        resultHours += 24;
      }
    
      const formattedHours = String(resultHours).padStart(2, '0');
      const formattedMinutes = String(resultMinutes).padStart(2, '0');
    
      return `${formattedHours}:${formattedMinutes}`;
    }


    // Check if it's the Summer hour. Eg:  Vienna has +1 from UTC as standard.  But during the summer time it has 1 hour more, so then +2 from UTC. 
    private isDaylightSavingTime(date: Date = new Date()): boolean {
      const year = date.getUTCFullYear();

      const startDST = new Date(Date.UTC(year, 2, 31)); // 31 March 00:00 UTC
      while (startDST.getUTCDay() !== 0) {
          startDST.setUTCDate(startDST.getUTCDate() - 1); // Last Sunday from March
      }

      const endDST = new Date(Date.UTC(year, 9, 31)); // 31 October 00:00 UTC
      while (endDST.getUTCDay() !== 0) {
          endDST.setUTCDate(endDST.getUTCDate() - 1); // Last Sunday from October
      }

      return date >= startDST && date < endDST;
  }
}