<div class="language-menu">
    <language-selector></language-selector>
</div>

<div class="logo-container">
    <div class="logo">
        <account-logo></account-logo>
    </div>
</div>

<div class="main-container">

    <div class="input-container">
        <div class="flex text-center justify-center mb-4">
            {{ 'invitation_user_register_title' | translate}}
        </div>

        <!-- Email disabled input -->
        <fs-input 
            [label]="'invitation_user_register_email' | translate" 
            [inputValue]="(data$ | async)?.email || ''"
            [style]="'full-width'" 
            [disabled]="true" >
        </fs-input>

        <!-- Restaurant disabled input -->
        <fs-input 
            [label]="'invitation_user_register_restaurant' | translate" 
            [inputValue]="(data$ | async)?.restaurantName || ''"
            [style]="'full-width'" 
            [disabled]="true">
        </fs-input>

        <div class="flex">
            <mat-card class="middle-card">
                <mat-card-content>
                    <div class="card-content">
                        <div class="card-left-side">
                            <mat-icon class="card-left-icon">info</mat-icon>
                        </div>
                        <div class="card-right-side">
                            {{ 'invitation_user_register_hint' | translate}}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        
        <div class="flex w-full mt-5">
            <!-- Name -->
            <fs-input [label]="'invitation_user_register_name' | translate" [style]="'full-width'" 
                (valueUpdate)="nameUpdate($event)"></fs-input>            
        </div>

        <div class="flex w-full ">
            <!-- Phone -->
            <fs-input [label]="'invitation_user_register_phone' | translate" [style]="'full-width'" 
                (valueUpdate)="phoneUpdate($event)"></fs-input>            
        </div>

        <div class="flex w-full">
            <!-- Password -->
            <fs-input [label]="'invitation_user_register_password' | translate"
                      [style]="'full-width'" [type]="passInputType"
                      (valueUpdate)="passwordUpdate($event)"
                      [trailingIcon]="trailingIcon"
                      [trailingIconFade]="passTrailingIconFade"
                      (trailingIconPress)="onPasswordIconPress()">
            </fs-input>
        </div>
        

        <div class="flex mt-4">
            <fs-button [text]="'invitation_user_submit' | translate"
                       [style]="'full-width-large-height'" [type]="'raised-primary'"
                       [disabled]="!(data$ | async)?.password || !(data$ | async)?.phoneNumber || !(data$ | async)?.name || !(data$ | async)?.restaurantName || !(data$ | async)?.email || !(data$ | async)?.id"
                       (onPress)="pressSubmit()"
                       [leadingIcon]="'account_circle'"></fs-button>
        </div>

        <div class="flex mt-12 not-received-text">
            {{ 'invitation_user_not_requested' | translate }}
        </div>
        <div class="flex not-received-text mt-1">
            <fs-link [text]="'invitation_user_not_requested_action' | translate" [buttonBehavior]="true" (press)="pressIgnore()"></fs-link>
        </div>
    </div>

</div>


