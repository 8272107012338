import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import { BaseLoadingService } from './base-loading';

@Injectable({
  providedIn: 'root'
})
export class TrackingService extends BaseLoadingService {

    private renderer: Renderer2;

    private readonly GTM_LINK = "https://www.googletagmanager.com/gtag/js?id=G-2G42C017BF";
    private readonly GTM_SCRIPT = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-2G42C017BF');
    `;

  constructor(
      @Inject(DOCUMENT) private document: Document,
      private rendererFactory: RendererFactory2,
  ) {
      super();
      this.renderer = this.rendererFactory.createRenderer(null, null);

      this.loadGTM(); //TODO Cata: in the future move this to be triggered only if the user accepted tracking cookies popup
      
      this.setFinished();
  }

    private loadGTM() {
      this.loadGoogleTagManagerURL();
    }

    private loadGoogleTagManagerURL() {
        const scriptElem = this.loadJsFile(this.renderer, this.GTM_LINK);
        scriptElem.onload = () => {
            this.loadGoogleTagManagerScript();
        }
    }

    private loadGoogleTagManagerScript() {
        this.loadJsScript(this.renderer, this.GTM_SCRIPT);
    }

    private loadJsFile(renderer: Renderer2, source: string): HTMLScriptElement {
        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = source;
        script.async  = true;
        renderer.appendChild(this.document.body, script);
        return script;
    }

    public loadJsScript(renderer: Renderer2, data: any) {
        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = data;
        renderer.appendChild(this.document.body, script);
    }
}
