import { Component } from '@angular/core';
import {Language, LocaleService} from "../../../../../../libs/shared-services/src/lib/locale.service";

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {

    public selectedMenuLanguage: number = 0;

    constructor(private localeService: LocaleService) {
        this.listenLanguageUpdate();
    }

    public getLanguages():string[] {
        return this.localeService.SUPPORTED_LOCALES.map((lang) => lang.name);
    }

    public listenLanguageUpdate() {
        this.localeService.getLanguage$().subscribe((lang: Language) => {
            let index = this.getLanguages().findIndex((item) => item === lang.name);
            if (index >= 0) {
                this.selectedMenuLanguage = index;
            }
        })
    }

    public languageMenuPress(value: number) {
        if (value === this.selectedMenuLanguage) {
            return;
        }

        let selected = this.getLanguages()[value];
        let newLang = this.localeService.SUPPORTED_LOCALES.find((lang) => lang.name === selected);
        if (!!newLang) {
            this.localeService.setLanguageISO2(newLang.iso2);
        }
    }
}
