import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {FsGenericModalComponent} from "../fs-generic-modal/fs-generic-modal.component";
import {Observable} from "rxjs";
import {FormatCentsToFloatPipe} from "../../../../shared-services/src/lib/cents-to-float-pipe";
import {LocaleTranslatePipe} from "../../../../shared-services/src/lib/locale-pipe";
import {LocaleService} from "../../../../shared-services/src/lib/locale.service";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
      public dialog: MatDialog,
      public localeService: LocaleService,

  ) {
  }

    public openConfirmDialog$(config: MatDialogConfig | null = null): Observable<any> {
        let tempConfig = null;
        if (config) {
            tempConfig = config;
        } else { // default:
            tempConfig = this.getDefaultDialogConfig();
            tempConfig.disableClose = true;
            tempConfig.data = {
                title: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_title"),
                midContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_body"),
                leftButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_left_action"),
                rightButtonContent: new LocaleTranslatePipe(this.localeService).transform("generic_modal_default_right_action"),
                preselectedButton: "left",
                oneButtonOnly: false
            }
        }

        return this.dialog.open(FsGenericModalComponent, tempConfig).beforeClosed();
    }

    public openCustomDialog(modalComponent: any, config: MatDialogConfig | null = null) {
        this.dialog.open(modalComponent, config ? config : this.getDefaultDialogConfig());
    }

    public openCustomDialog$(modalComponent: any, config: MatDialogConfig | null = null): Observable<any> {
        return this.dialog.open(modalComponent, config ? config : this.getDefaultDialogConfig()).beforeClosed();
    }

    public getDefaultDialogConfig(): MatDialogConfig {
      return {
          closeOnNavigation: true,
          data: null,
          delayFocusTrap: false,
          disableClose: false,
          enterAnimationDuration: "0ms",
          exitAnimationDuration: "50ms",
          hasBackdrop: true,
          height: "auto",
          width: "auto"
      }
    }
}
