import { Injectable } from '@angular/core';
import { BaseLoadingService } from './base-loading';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService extends BaseLoadingService {

  // These are CET. For the summer time (we separately add +1 in the Timezone Pipe when displaying the hour)
  private TIMEZONE_LIST = [
    {"offset": "-12:00", "city": "Baker Island, Funafuti, Fakaofo"},
    {"offset": "-11:00", "city": "Pago Pago, Alofi, Niue"},
    {"offset": "-10:00", "city": "Honolulu, Papeete, Rarotonga"},
    {"offset": "-09:00", "city": "Anchorage, Gambell, Kodiak"},
    {"offset": "-08:00", "city": "Los Angeles, Vancouver, Tijuana"},
    {"offset": "-07:00", "city": "Denver, Calgary, Phoenix"},
    {"offset": "-06:00", "city": "Chicago, Mexico City, Guatemala City"},
    {"offset": "-05:00", "city": "New York, Toronto, Lima"},
    {"offset": "-04:00", "city": "Santiago, Caracas, La Paz"},
    {"offset": "-03:00", "city": "Buenos Aires, Montevideo, Sao Paulo"},
    {"offset": "-02:00", "city": "South Georgia, Stanley, King Edward Point"},
    {"offset": "-01:00", "city": "Azores, Cape Verde, Ittoqqortoormiit"},
    {"offset": "+00:00", "city": "London, Dublin, Lisbon"},
    {"offset": "+01:00", "city": "Vienna, Berlin, Paris"},
    {"offset": "+02:00", "city": "Cairo, Athens, Bucharest"},
    {"offset": "+03:00", "city": "Moscow, Istanbul, Baghdad"},
    {"offset": "+04:00", "city": "Dubai, Baku, Tbilisi"},
    {"offset": "+05:00", "city": "Tashkent, Islamabad, Karachi"},
    {"offset": "+06:00", "city": "Almaty, Dhaka, Bishkek"},
    {"offset": "+07:00", "city": "Bangkok, Jakarta, Hanoi"},
    {"offset": "+08:00", "city": "Beijing, Singapore, Hong Kong"},
    {"offset": "+09:00", "city": "Tokyo, Seoul, Pyongyang"},
    {"offset": "+10:00", "city": "Sydney, Brisbane, Port Moresby"},
    {"offset": "+11:00", "city": "Honiara, Noumea, Pohnpei"},
    {"offset": "+12:00", "city": "Suva, Majuro, Tarawa"},
    {"offset": "+13:00", "city": "Nuku'alofa, Apia, Fakaofo"},
    {"offset": "+14:00", "city": "Kiritimati, Tabwakea, London"}
  ];

  constructor() { 
    super();
    this.setFinished();
  }

  private getTimezones(): any[] {
    return this.TIMEZONE_LIST;
  }

  public getTimezoneCity(hour: string): string {
    const timezone = this.getTimezones().find(tz => tz.offset === hour);
    return timezone ? timezone.city : 'Timezone not found';
  }

  public getTimezoneFull(hour: string): string {
    return "(" + hour + ") " + this.getTimezoneCity(hour);
  }

  public getAllTimezonesFull(): string[] {
    return this.getTimezones().map((item) => this.getTimezoneFull(item.offset));
  }

  public getOffsetByTimezoneFull(s: string): string {
    return this.getTimezones().find(tz => this.getTimezoneFull(tz.offset) === s)?.offset || '';
  }
  
}
