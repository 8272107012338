<button mat-button [matMenuTriggerFor]="menu" [disabled]="disabled">
    <div class="flex">

        <!-- Leading icon -->
        <ng-container *ngIf="!!leadingIcon">
            <img [src]="leadingIcon" alt="language globe"/>
        </ng-container>

        <!-- Middle content -->
        <span class="main-item" [ngClass]="{'main-item-disabled': disabled}">
            <ng-container *ngIf="!!preselectPlaceholder && !selectedItem">
                {{ preselectPlaceholder }}
            </ng-container>
            <ng-container *ngIf="!!selectedItem">
                {{ selectedItem[itemValueAttribute] }}
            </ng-container>
        </span>

        <!-- Dropdown icon -->
        <ng-container *ngIf="!disabled">
          <span class="flex">
              <img src="./libs/shared-ui/assets/icons/arrow-down.svg" alt="dropdown arrow"/>
          </span>
        </ng-container>
    </div>
</button>
<mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of itemsList; index as i">
        <button mat-menu-item [id]="!!item && !!item[itemIdAttribute] ? item[itemIdAttribute] : ''" (click)="onItemSelect($event)"
                [ngClass]="{'selected-item' : ((!!selectedItem ? selectedItem[itemIdAttribute] : '') === (!!item ? item[itemIdAttribute] : ''))}">
            {{ item[itemValueAttribute] ?? '' }}
        </button>
    </ng-container>
</mat-menu>
