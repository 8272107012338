<ng-container *ngIf="showManualStep(); else automatedStep">
    <div class="language-menu">
        <language-selector></language-selector>
    </div>

    <div class="logo-container">
        <div class="logo">
            <account-logo></account-logo>
        </div>
    </div>

    <div class="main-container">

        <div class="input-container">
            <div class="flex mb-4">
                {{ 'register_activate_manual_description' | translate}}
            </div>

            <div class="flex mt-3">
                <fs-input [label]="'register_activate_code' | translate"
                          [style]="'full-width'"
                          (valueUpdate)="codeUpdate($event)"></fs-input>
            </div>

            <div class="flex mt-3">
                <fs-button [text]="'register_activate_submit_button' | translate" [style]="'full-width-large-height'"
                           [type]="'raised-primary'" (onPress)="submitCodeClick()"></fs-button>
            </div>

            <div class="flex w-full mt-20 mb-3">
                <mat-divider class="flex w-full"></mat-divider>
            </div>

            <div class="flex mt-4">
                <fs-button [url]="'login'" [text]="'register_confirm_login_button' | translate"
                           [style]="'full-width-large-height'" [type]="'raised'"
                           [leadingIcon]="'account_circle'"></fs-button>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #automatedStep>
    <!-- nothing for now -->
</ng-template>