import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseLoadingService {

  protected initFinished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }
  
  protected setFinished() {
    this.initFinished$.next(true);
  }

  public isFinished$(): Observable<boolean> {
    return this.initFinished$.asObservable();
  }

  public isFinished(): boolean {
    return this.initFinished$.value;
  }
}
