import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'fs-button',
  templateUrl: './fs-button.component.html',
  styleUrls: ['./fs-button.component.scss'],
})
export class FsButtonComponent {
    @Input() text: string = "";
    @Input() style: string = "normal";
    @Input() type: string = "basic"
    @Input() leadingIcon: string = "";
    @Input() leadingIconUrl: string = "";
    @Input() leadingIconStyle: string = "custom-raised-icon-normal";
    @Input() urlFull: string = ""; // external or full path url
    @Input() url :string = "" // router navigate url
    @Input() disabled: boolean = false;

    @Output() onPress: EventEmitter<any> = new EventEmitter<any>();

    constructor(private router: Router) {
    }


    public onClick($event: any) {
        if (!!this.url) {
            this.router.navigateByUrl(this.url)
        }
        else if (!!this.urlFull) {
            window.location.href = this.urlFull
        }
        else {
            this.onPress.emit();
        }
    }
}
