import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter, Input, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { BehaviorSubject, Observable, debounceTime, fromEvent } from "rxjs";

import { addressAnimations } from "libs/shared-models/src/lib/address/address-animations";
import { AddressResponse } from "libs/shared-models/src/lib/address-response";
import { DISTANCE_PINPOINT_THRESHOLD, mapViennaOptions, mapViennaOptionsCenter } from "libs/shared-models/src/lib/utils/address-constants";
import { haversine_distance } from "libs/shared-models/src/lib/utils/address-functions";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'fs-base-address-editor',
  templateUrl: './fs-base-address-editor.component.html',
  styleUrls: ['./fs-base-address-editor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: addressAnimations
})

export class BaseAddressEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    /*
        Interacting with Google Maps api:
    */
    @ViewChild('mapSearchField') searchField: ElementRef | undefined;
    private map: google.maps.Map | undefined; // <google-map> component
    private mapMarker: google.maps.Marker | undefined;  // Pinpoint on the map
    private gAutocomplete: google.maps.places.Autocomplete | undefined // Places Autocomplete container
    public defaultMapOptions: google.maps.MapOptions = mapViennaOptions; // Boundaries region (Vienna)

    /*
        Internal states:
    */
    private showExtendedEdit$: BehaviorSubject<ExtendedEditVisibility> = new BehaviorSubject<ExtendedEditVisibility>(new ExtendedEditVisibility()); // View with input fields and map
    private temporaryAddress$: BehaviorSubject<AddressResponse> = new BehaviorSubject<AddressResponse>(new AddressResponse(true)); // Edit one
    private clearButtonVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // Small functionality

    // Input fields part (part of the extended)
    public canShowExtendedStep1$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // by default it is shown
    // Google map part (part of the extended)
    public canShowExtendedStep2$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // by default it is shown

    public screenHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(768); // some wide default
    public screenWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(1024); // some wide default
    public isCompactVersion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // the part 1 & 2 are separated in 2 logical steps
    
    // See all addreses list (minimize / maximize)
    public seeAllExtended$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    /*
        External comms
    */
    @Input() addressList: AddressResponse[] = [];
    @Input() translations: AddressTranslations | null;
    @Input() usage: AddressUsageEnum = AddressUsageEnum.USER_APP;

    @Output() onConfirmAddress: EventEmitter<AddressResponse> = new EventEmitter<AddressResponse>();
    @Output() onSetDefaultAddress: EventEmitter<AddressResponse> = new EventEmitter<AddressResponse>();
    @Output() onDeleteAddress: EventEmitter<AddressResponse> = new EventEmitter<AddressResponse>();
    @Output() onBrowserBlockedError: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBrowserNotSupportedError: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBackgroundPress: EventEmitter<any> = new EventEmitter<any>();

    public AddressUsageEnum: typeof AddressUsageEnum = AddressUsageEnum;

    public constructor(
        private cdRef: ChangeDetectorRef
    ) {
    }

    public ngOnInit() {
        this.checkIsCompactVersion();
        this.listenToWindowResize();
    }
    
    public ngAfterViewInit() {
        this.setupInput(); // needed after view init for Google Maps  (onInit doesn't work)
        this.cdRef.detectChanges();
    }

    private listenToWindowResize() {
        fromEvent(window, 'resize').pipe(untilDestroyed(this)).subscribe((a) => {            
            this.checkIsCompactVersion();
        });
    }

    /*
        This checks if it should render 2 steps in one single view (part 1/2 and part 2/2)
        Or if it's the compact (or mobile) resize (so then step 1 and step 2 are separately loaded / with interactions between them next/back)
    */
    private checkIsCompactVersion() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const isCompact = width < 992;
        this.screenWidth$.next(width);  
        this.screenHeight$.next(height);
        this.isCompactVersion$.next(isCompact);

        if (!isCompact) {
            this.canShowExtendedStep1$.next(true);
            this.canShowExtendedStep2$.next(true);
            return;
        }

        // by default, if both are shown, just load the first one;
        if (isCompact && this.canShowExtendedStep1$.getValue() && this.canShowExtendedStep2$) {
            this.canShowExtendedStep2$.next(false); // hide the map by default
            return;
        } 
    }

    public onBackCompactExtended() {
        this.canShowExtendedStep1$.next(true);
        this.cdRef.detectChanges();
        this.fillSearchInput(this.getTempAddress());
        this.canShowExtendedStep2$.next(false);
    }


    /*
       Init for input / google component
    */
    private setupInput() {

        // Search, Places API
        if (!!this.searchField) {

            // Focus the input cursor / select input
            this.searchField.nativeElement.focus();

            // Create a bounding box with sides ~17km away from the center point
            const center = mapViennaOptionsCenter;
            const defaultBounds = {
                north: center.lat + 0.17,
                south: center.lat - 0.17,
                east: center.lng + 0.17,
                west: center.lng - 0.17,
            };
            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: "at" },
                fields: ["address_components", "geometry", "icon", "name"],
                strictBounds: true,
            };

            this.clearMapsListener();

            this.gAutocomplete = new google.maps.places.Autocomplete(this.searchField.nativeElement, options);
            this.gAutocomplete.addListener('place_changed', () => {
                this.onPlaceSelected();
            })
        } else {
            // An error appeared when loading Google api
        }
    }

    public onMapReady(value: google.maps.Map) {
        this.map = value;
        this.addMarker();
    }

    // clear any existing listener / instance
    private clearMapsListener() {
        if (!!this.gAutocomplete) {
            this.gAutocomplete.unbindAll();
            google.maps.event.clearListeners(this.gAutocomplete, 'place_changed');
        }
    }

    /*
       Listen to Google Search dropdown address Select
    */
    private onPlaceSelected() {
        const place = this.gAutocomplete?.getPlace();
        this.parseAddressResponse(place);
    }

    // Used by both Places Autocomplete (input) and the Geocoding (browser target location)
    private parseAddressResponse(place: any) {
        if (!place) {
            return;
        }

        if (!place.address_components || place.address_components?.length === 0) {
            return;
        }

        // Our internal model
        let address;
        // Check if we just need to edit (and clear) an existing address (which gets new geolocation, or if it's the first time and we generate a new one)
        if (!this.getTempAddress().getIsTemporary() || !this.getTempAddress().hasGeneratedId() || this.getTempAddress().getIsLocallyStored()) {
            address = Object.assign(new AddressResponse(), this.getTempAddress())
            address.setStreetName("");
            address.setStreetNumber("");
            address.setPostalCode("");
        } else {
            address = new AddressResponse(true);
        }

        // Address components (street, zip, city):
        for (const addressComponent of place.address_components) {
            const type = addressComponent.types[0];

            switch (type) {
                case "locality": {
                    address.setCity(addressComponent.long_name);
                    break;
                }
                case "route": {
                    address.setStreetName(addressComponent.short_name + address.getStreetName())
                    break;
                }
                case "street_number": {
                    address.setStreetNumber(addressComponent.long_name)
                    break;
                }
                case "country": {
                    address.setCountry(addressComponent.long_name)
                    break;
                }
                case "administrative_area_level_1": {
                    address.setState(addressComponent.short_name)
                    break;
                }
                case "postal_code": {
                    address.setPostalCode(addressComponent.long_name + address.getPostalCode());
                    break;
                }
                case "postal_code_suffix": {
                    address.setPostalCode(address.getPostalCode() + "-" + addressComponent.long_name);
                    break;
                }
                case "sublocality_level_1": {
                    address.setDistrict(addressComponent.long_name);
                    break;
                }
                default:
                    break;
            }
        }

        // Geometry components (latitude, longitude)
        if (!!place.geometry && !!place.geometry.location) {
            const lat = place.geometry.location.lat();
            const long = place.geometry.location.lng();
            address.setLatitude(!!lat ? lat : 0);
            address.setLongitude(!!long ? long : 0);
        }

        // Update current state
        this.setTempAddress(address);

        // Show the extended version (with input fields)
        this.setExtendedVisibility(true);

        this.cdRef.detectChanges();
    }

    private addMarker() {
        // Marker icon
        const image = {
            url: "./libs/shared-ui/assets/images/address-pin-google-map.png",
            // This marker is 20 pixels wide by 32 pixels high.
            size: new google.maps.Size(51, 67),
            // The origin for this image is (0, 0).
            origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            anchor: new google.maps.Point(25, 67),
        };

        // Add marker
        this.mapMarker = new google.maps.Marker({
            map: this.map,
            draggable: true,
            animation: google.maps.Animation.DROP,
            icon: image,
            position: {
                lat: this.getTempAddress().getLatitude(),
                lng: this.getTempAddress().getLongitude()
            },
        });
        this.mapMarker.addListener("dragend", this.onMarkerClick.bind(this));
    }

    private onMarkerClick() {
        let initialLat = this.getTempAddress().getLatitude();
        let initialLong = this.getTempAddress().getLongitude();

        // Take the new values after the Drag & Drop and update Current Address
        let newLat = this.mapMarker?.getPosition()?.lat() || 0;
        let newLong = this.mapMarker?.getPosition()?.lng() || 0;
        if (!!newLat && !!newLong) {
            let a = this.getTempAddress();
            a.setLatitude(newLat);
            a.setLongitude(newLong);
            this.setTempAddress(a);
        }

        // Center the map in the new position
        let latLong  = new google.maps.LatLng(newLat, newLong)
        this.map?.setCenter(latLong);

        // Calculate the difference between the initial position and the dragged on - in order to search for the new address
        const initialPosition = {
            lat: initialLat,
            lng: initialLong,
        };
        const newPosition = {
            lat: newLat,
            lng: newLong,
        };

        // When the distance difference between the initial and new Pinpoint positions surpass the threshold, then we re-trigger an address search
        let distance = haversine_distance(initialPosition, newPosition);
        if (distance > DISTANCE_PINPOINT_THRESHOLD) {
            this.handleGeocoding(newPosition);
        }
    }


    /*
        Get state (address, extended)
    */
    public isExtended$(): Observable<ExtendedEditVisibility> {
        return this.showExtendedEdit$.asObservable();
    }

    public isExtended(): boolean {
        return this.showExtendedEdit$.getValue().value;
    }

    public getTempAddress$(): Observable<AddressResponse> {
        return this.temporaryAddress$.asObservable();
    }

    public getTempAddress(): AddressResponse {
        return this.temporaryAddress$.getValue();
    }

    private setTempAddress(a: AddressResponse): void {
        const newAddress = Object.assign(new AddressResponse(), a);
        this.temporaryAddress$.next(newAddress); 
    }

    private setExtendedVisibility(b: boolean) {
        let curr = Object.assign(new ExtendedEditVisibility(), this.showExtendedEdit$.getValue());
        curr.value = b;
        this.showExtendedEdit$.next(curr);
    }

    /*
        Select Default from list
    */
    public onExistingAddressSelect(a: AddressResponse) {
        
        if (this.usage === AddressUsageEnum.RESTAURANT) {
            this.onEditAddress(a);
            return;
        }

        if (a.getIsDefault()) {
            return; // ignore click
        }
        
        this.onSetDefaultAddress.emit(a);
    }

    /*
        Edit click
    */
    public onEditAddress(a: AddressResponse) {
        // Update the current Edit Address
        this.setTempAddress(a);

        this.fillSearchInput(a);

        // Show the extended version (with input fields)
        this.setExtendedVisibility(true);
    }
    
    private fillSearchInput(a: AddressResponse) {
        // Fill-in the top input        
        if (!!this.searchField) {            
            this.searchField.nativeElement.value = a.getStreetName() + " " + a.getStreetNumber() + ", " + a.getCity() + ", " + a.getPostalCode();
        }
    }


    /*
        Delete
    */
    public onDeleteAddressClick(a: AddressResponse) {
        this.onDeleteAddress.emit(a);

        // If it is opened in the Extended version, then just go back to the opened small version
        if (this.isExtended() && (!a.getIsTemporary() || a.getIsLocallyStored())) {
            this.setExtendedVisibility(false);
        }
    }

    /*
        Back (from extended input)
    */
    public onBackClick() {
        this.setExtendedVisibility(false);
        if (!!this.searchField && (!this.getTempAddress().getIsTemporary() && !!this.searchField || !this.getTempAddress().hasGeneratedId() || this.getTempAddress().getIsLocallyStored())) {
            this.searchField.nativeElement.value = ""; // clear the input
        }
        setTimeout( () => {
            this.searchField?.nativeElement.focus({ focusVisible: true });
        }, 200);

    }

    /*
       Clear button
    */
    public isClearButtonVisible$(): Observable<boolean> {
        return this.clearButtonVisibility$.asObservable();
    }

    public inputValueChanged() {
        if (!!this.searchField?.nativeElement.value) {
            this.clearButtonVisibility$.next(true);
        } else {
            this.clearButtonVisibility$.next(false);
        }
    }

    public onClearClick() {
        if (!!this.searchField) {
            this.searchField.nativeElement.value = "";
        }
        if (this.isExtended()) {
            this.onBackClick();
        }
        this.clearButtonVisibility$.next(false);
    }

    /*
        Dismiss via background
    */
    public onBackgroundDismiss($event: any) {
        if ($event?.target?.id === "overallBackground") {
            this.onBackgroundPress.emit(true);
        }
    }

    /*
        Browser target location
     */
    public onBrowserLocationClick() {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position: GeolocationPosition) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    this.handleGeocoding(pos);
                },
                () => {
                    this.onBrowserBlockedError.emit();
                },
            );
        } else {
            // Browser doesn't support Geolocation
            this.onBrowserNotSupportedError.emit();
        }
    };

    /*
        Access the Geocoding API to transform latitude/longitude in Address
     */
    private handleGeocoding(position: any) {
        let geocoder = new google.maps.Geocoder();
        let place = null;

        geocoder.geocode({ location: position })
            .then((response) => {
                if (response.results[0]) {
                    place = response.results[0];

                    if (!!place) {
                        this.parseAddressResponse(place); // Set the address / open the extended view with the map
                    }
                } else {
                    console.warn("Browser location: Google Geocoding API - No results found");
                }
            })
            .catch((e) => console.error("Geocoder failed due to: " + e));
    }


    /*
       Input fields updates
    */
    public streetUpdate(value: string) {
        let temp = this.getTempAddress();
        if (temp.getStreetName() === value) {
            return;
        }
        temp.setStreetName(value);
        this.setTempAddress(temp);
    }

    public streetNumberUpdate(value: string) {
        let temp = this.getTempAddress();
        if (temp.getStreetNumber() === value) {
            return;
        }
        temp.setStreetNumber(value);
        this.setTempAddress(temp);
    }

    public entranceUpdate(value: string) {
        let temp = this.getTempAddress();
        if (temp.getEntrance() === value) {
            return;
        }
        temp.setEntrance(value);
        this.setTempAddress(temp);
    }

    public floorUpdate(value: string) {
        let temp = this.getTempAddress();
        if (temp.getFloor() === value) {
            return;
        }
        temp.setFloor(value);
        this.setTempAddress(temp);
    }

    public doorUpdate(value: string) {
        let temp = this.getTempAddress();
        if (temp.getDoorNumber() === value) {
            return;
        }
        temp.setDoorNumber(value);
        this.setTempAddress(temp);
    }

    public postalCodeUpdate(value: string) {
        let temp = this.getTempAddress();
        if (temp.getPostalCode() === value) {
            return;
        }
        temp.setPostalCode(value);
        this.setTempAddress(temp);
    }

    public additionalInfoUpdate(value: string) {
        let temp = this.getTempAddress();
        if (temp.getAdditionalInfo() === value) {
            return;
        }
        temp.setAdditionalInfo(value);
        this.setTempAddress(temp);
    }

    public submitClick() {
        // do the checks ->  Move to the next field TAB
        // or submit it automatically (confirm)
    }


    /*
       Confirm button
    */
    public confirmAddressClick() {
        const address = this.getTempAddress();
        this.onConfirmAddress.emit(address);
    }

    public confirmMobileAddressClick() {
        this.canShowExtendedStep1$.next(false);
        this.canShowExtendedStep2$.next(true);
    }

    public onMapClick(event: any) {
    }

    /*
        See all list of addresses (expand)
    */
    public onToggleSeeAll() {
        this.seeAllExtended$.next(!this.seeAllExtended$.getValue());
    }

    public ngOnDestroy() {
        this.clearMapsListener();
    }
}

// Added a class, so we can have an instance (for immutability). Seems the simple boolean true/false doesn't always work as expected. So we want a real object with instance which holds the boolean property
export class ExtendedEditVisibility {
    public value: boolean = false;

    constructor() {
    }
}

export interface AddressTranslations {
    address_image_title_back_search: string;
    search_address_placeholder: string;
    address_image_title_clear_input: string;
    address_image_title_browser_location: string;
    address_stored_title: string;
    address_image_title_default: string;
    address_image_title_edit: string;
    address_image_title_delete: string;
    address_stored_see_all: string;
    address_extended_more: string;
    address_extended_delete: string;
    address_extended_button_confirm: string;
    address_extended_button_save_changes: string;    
    address_extended_instructions: string;    
    address_extended_postal_code: string;    
    address_extended_door: string;    
    address_extended_floor: string;    
    address_extended_entrance: string;    
    address_extended_street_number: string;    
    address_extended_street: string;    
}

export enum AddressUsageEnum {
    USER_APP = "USER_APP",
    RESTAURANT = "RESTAURANT"
}