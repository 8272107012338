import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { BaseLoadingService } from './base-loading';

@Injectable({
  providedIn: 'root'
})
export class ToasterService extends BaseLoadingService {

  constructor(private toastr: ToastrService) {
    super();    
    this.setFinished();
  }

  public showSuccess(title: string = "", message: string = "") {
      this.toastr.success(message, title);
  }

    public showError(title: string = "", message: string = "") {
        this.toastr.error(message, title);
    }

    public showWarning(title: string = "", message: string = "") {
        this.toastr.warning(message, title);
    }

    public showInfo(title: string = "", message: string = "") {
        this.toastr.info(message, title);
    }
}
