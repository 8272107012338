import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

class DialogAnimationsExampleDialog {
}

@Component({
  selector: 'fs-generic-modal',
  templateUrl: './fs-generic-modal.component.html',
  styleUrls: ['./fs-generic-modal.component.scss'],
})
export class FsGenericModalComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>) {
    }

    public ngOnInit() {
        this.dialogRef.backdropClick().subscribe(() => {
            this.onPressNo();
        });
    }

    public onPressNo() {
        this.dialogRef.close(false);
    }

    public onPressYes() {
        this.dialogRef.close(true);
    }
}
