import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ApiBaseService} from "libs/shared-services/src/lib/api-base.service";
import {ToasterService} from "libs/shared-services/src/lib/toaster.service";
import {LocaleService} from "libs/shared-services/src/lib/locale.service";
import {Subject, throttleTime} from "rxjs";
import {PageTitleService} from "libs/shared-services/src/lib/page-title.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'register-activate',
  templateUrl: './register-activate.component.html',
  styleUrls: ['./register-activate.component.scss'],
})
export class RegisterActivateComponent implements OnInit {

    private pageTitle = "page_title_register_activate";

    public externalUrlCode: string = "";
    private manualCode: string = "";

    private submitClicks = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiBaseService,
        private toasterService: ToasterService,
        private localeService: LocaleService,
        private pageTitleService: PageTitleService
    ) {
    }

    public ngOnInit() {
        this.route.paramMap.subscribe(params => {
            let code = params.get('code');
            if (!!code) {
                this.externalUrlCode = code;
                this.submitCodeToApi();
            }
        });

        this.submitClicks.pipe(
            throttleTime(1500)
        ).pipe(untilDestroyed(this)).subscribe(e => {
            if (!this.manualCode) {
                this.toasterService.showInfo("", this.localeService.translate("register_activate_missing_code"));
                return;
            }
            this.submitCodeToApi();
        });
        this.localeService.getLanguage$().pipe(untilDestroyed(this)).subscribe(() => {
            this.pageTitleService.setTitle(this.localeService.translate(this.pageTitle));
        })
    }

    private submitCodeToApi() {
        let code = !!this.externalUrlCode ? this.externalUrlCode : this.manualCode;
        let url = environment.API_ACTIVATE_EMAIL_URL + "?activationKey=" + code;

        this.apiService.get(url).subscribe((res) => {
            this.toasterService.showSuccess(
                this.localeService.translate("register_activate_success_title"),
                this.localeService.translate("register_activate_success_description"));
            this.router.navigateByUrl("login");
        }, (err) => {
            this.toasterService.showError("Error", err?.error?.message);

            // clear external code, so it goes to the manual step:
            this.externalUrlCode = "";
        })
    }

    public codeUpdate(value: string) {
        this.manualCode = value;
    }

    public showManualStep(): boolean {
        return !this.externalUrlCode;
    }

    public submitCodeClick() {
        this.submitClicks.next(true);
    }
}
