import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {CookieService} from "./cookie.service";
import {Product} from "../../../shared-models/src/lib/product-type";
import { BaseLoadingService } from './base-loading';

@Injectable({
  providedIn: 'root'
})
export class CurrentProductService extends BaseLoadingService {

    private $product: BehaviorSubject<Product> = new BehaviorSubject<Product>(Product.UNDEFINED);

    constructor(
        private cookieService: CookieService,
        @Inject('env') private environment: any) {
        super();
        this.checkExistingCookie();        
        this.setFinished();
    }

    private checkExistingCookie() {
        let p = this.cookieService.getCookie("product");
        if (!!p && Object.values(Product).includes(p as Product)) {
            this.setProduct(p as Product);
        }
    }

    public getProduct(): Product {
        return this.$product.getValue();
    }

    public $getProduct(): Observable<Product> {
        return this.$product.asObservable();
    }

    public setProduct(p: Product) {
        this.$product.next(p);
        this.cookieService.setCookie("product", p);
    }

    public getProductURL(): string {
        if (this.isRestaurant()) {
            return this.environment.PRODUCT_URLS.RESTAURANT;
        }
        if (this.isDriver()) {
            return this.environment.PRODUCT_URLS.DRIVER;
        }
        if (this.isUserApp()) {
            return this.environment.PRODUCT_URLS.USER_APP;
        }
        if (this.isAdmin()) {
            return this.environment.PRODUCT_URLS.ADMIN;
        }
        return this.environment.PRODUCT_URLS.LANDING_PAGE; // default
        //TODO Cata - maybe improve this and make it more dynamically
    }

    public isUndefined(): boolean {
        return this.getProduct() === Product.UNDEFINED;
    }

    public isRestaurant(): boolean {
        return this.getProduct() === Product.RESTAURANT;
    }

    public isUserApp(): boolean {
        return this.getProduct() === Product.USER_APP;
    }

    public isDriver(): boolean {
        return this.getProduct() === Product.DRIVER;
    }

    public isAdmin(): boolean {
        return this.getProduct() === Product.ADMIN;
    }
}

