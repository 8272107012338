import { COMMA, ENTER} from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'fs-chip-list',
  templateUrl: './fs-chip-list.component.html',
  styleUrls: ['./fs-chip-list.component.scss'],
})

export class FsChipListComponent implements OnInit {
  
  @Input() set options(list: IFsChipsItem[]) {
    this.fullList = list;
    this._idList = list.map((item) => item.id); // this one is used for the component
  }
  @Input() areaLabel: string = "" 
  @Input() title: string = ""; // text
  @Input() searchPlaceholder: string = ""; // text
  @Input() limitReached: string = ""; // text
  @Input() leadingIcon: string = "";
  @Input() trailingIcon: string = "";
  @Input() maxSelections: number = 10;
  @Input() set preselectedList(list: string[]) {
    if (!list || JSON.stringify(this._preselectedList) === JSON.stringify(list)) {
      return;
    }
    this._preselectedList = list;
    this.selectedList = this._preselectedList;
    this.listFormControl.setValue(null);
  }
  _preselectedList: string[] = [];

  public fullList: IFsChipsItem[] = []; // this is used just to separately store the localized text (from .text attribute)
  _idList: string [] = []; // this is stored in the logic.  
  
  selectedList: string[] = [];  // current list of selected options
  separatorKeysCodes: number[] = [ENTER, COMMA];
  listFormControl = new FormControl('');
  filteredItems: Observable<string[]>;  

  @Output() onListChange: EventEmitter<any> = new EventEmitter<any>()


  // Input field
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  // accessibility
  announcer = inject(LiveAnnouncer);

  public constructor() {
  }

  public ngOnInit(): void {
    // listener on input text change (search)
    this.filteredItems = this.listFormControl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filter(item) : this._idList.filter((i) => !this.isInsideIdSelected(i)).slice())),
    );

    // init
    this.selectedList = this._preselectedList;
  }

  // Input add
  public add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our item in the chosen list
    if (value && this.isInsideIdSelected(value) && this.selectedList.length < (this.maxSelections)) {
      this.selectedList.push(value);
      this.onListChange.emit(this.selectedList);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.listFormControl.setValue(null);
  }

  // (x) Remove icon clicked
  remove(item: string): void {
    const index = this.selectedList.indexOf(item);


    if (index >= 0) {
      this.selectedList.splice(index, 1);
      this.onListChange.emit(this.selectedList);

      // Accessibility 
      this.announcer.announce(`Removed ${item}`);
    }

    // clear / reset the autocomplete list
    this.searchInput.nativeElement.value = '';
    this.listFormControl.setValue(null);
  }

  // Autocomplete selected
  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedList.length < (this.maxSelections)) {
      this.selectedList.push(event.option.value); // option.value is the ID
    }
    
    this.onListChange.emit(this.selectedList);
    this.searchInput.nativeElement.value = '';
    this.listFormControl.setValue(null);
  }

  // Search input filtering
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this._idList.filter(item => {
      return this.getTextById(item).toLowerCase().includes(filterValue) 
              && !this.isInsideIdSelected(item);
    });
  }

  private isInsideIdList(id: string): boolean {
    return !!this._idList.find((item) => item === id);
  }

  private isInsideIdSelected(id: string): boolean {
    return !!this.selectedList?.find((item) => item === id);
  }

  // As we always keep the ID saved in the list, we use this to just display the localized text instead
  public getTextById(id: string): string {
    if (!this.fullList || this.fullList.length === 0) {
      return "";
    }
    return this.fullList.find((i) => i.id === id)?.text || "";
  }
}

export interface IFsChipsItem {
  id: string;
  text: string;
}