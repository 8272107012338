import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesToMB'
})
export class BytesToMegaBytesPipe implements PipeTransform {

  public transform(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024,
          dm = decimals < 0 ? 0 : decimals,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));

    if (i === 0) {
      // For byte sizes
      return bytes + ' ' + sizes[i];
    } else {
      // For KB, MB, and beyond
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
}