<form class="main-container">
    <mat-form-field class="form-field">
        <!-- Title -->
        <mat-label class="title-container"> 
            <ng-container *ngIf="!!leadingIcon">
                <mat-icon class="leading-icon" >{{ leadingIcon }}</mat-icon>
            </ng-container>            
            {{ title }}
            <ng-container *ngIf="!!trailingIcon && selectedList?.length === 0">
                <mat-icon class="trailing-icon" >{{ trailingIcon }}</mat-icon>
            </ng-container>  
        </mat-label>

        <!-- List -->
        <mat-chip-grid #chipGrid [ariaLabel]="areaLabel">
            @for (item of selectedList; track item) {
                <mat-chip-row (removed)="remove(item)">
                    <!-- Name -->
                    {{ getTextById(item) }}
                    <!-- (x) remove -->
                    <button matChipRemove [attr.aria-label]="'remove ' + item">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            }
        </mat-chip-grid>

        <!-- Input search / select -->
        <input [placeholder]="(selectedList || []).length === maxSelections ? limitReached : searchPlaceholder" #searchInput [formControl]="listFormControl"
            [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
        />

        <!-- Autocomplete -->
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            @for (item of filteredItems | async; track item) {
                <mat-option [value]="item">
                    {{ getTextById(item) }}
                </mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>
  </form>