import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BaseLoadingService } from './base-loading';
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends BaseLoadingService {

    private localStorageAESKey = "";

    constructor() { 
        super();    
        this.initLocalStorageAESKey();    
        this.setFinished();
    }

    private initLocalStorageAESKey() {
        // Check if an ID exists in local storage
        let uniqueID = localStorage.getItem('fs-session-encrypt-key');
        if (!uniqueID) {
            // make sure local storage is clean (previous malformed versions of storing localstorage data)
            this.clearData();

            // Generate a new UUID 4 unique ID and store it
            uniqueID = uuidv4();
            localStorage.setItem('fs-session-encrypt-key', uniqueID);            
        }
        this.localStorageAESKey = uniqueID;
    }

    private encrypt(value: string): string {
        return CryptoJS.AES.encrypt(value, this.localStorageAESKey).toString();
    }

    private decrypt(value: string) {
        return CryptoJS.AES.decrypt(value, this.localStorageAESKey).toString(CryptoJS.enc.Utf8);
    }

    public saveData(key: string, value: string, encrypted: boolean = true) {
        localStorage.setItem(key, encrypted ? this.encrypt(value) : value);
    }

    public getData(key: string, encrypted: boolean = true) {
        const data = localStorage.getItem(key) || "";
        return encrypted ? this.decrypt(data) : data;
    }

    public removeData(key: string) {
        localStorage.removeItem(key);
    }

    public clearData() {
        localStorage.clear();
    }
}
